import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Profil, UtilisateurService, AuthenticatedUserResolver } from '@terega-portal/auth-lib';
import { Observable, Subject } from 'rxjs';
import packageInfo from '../../package.json';
import { EventsService } from './events/events.service';
import { EntityFilterStatusStore } from './shared/entities/entity-filter-status.store';
import { EntityStore } from './shared/entities/entity.store';
import { RouterEventsListener } from './shared/router/router-events.listener';
import { RouterResolverBuilder } from './shared/router/router-resolver.builder';
import { RouterEventsGAListener } from './shared/router/router-events-ga.listener';
import { pluck, startWith } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { DATE_FNS_LOCALE_MAP } from './shared/constants/mat-date-fns/date-fns-locale-map';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('darkenBackground', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 0.7 }))]),
      transition(':leave', [style({ opacity: 0.7 }), animate('200ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  unreadEventsCount$: Observable<number>;
  showEventsNotifications = false;
  hasEntityFilterAccess = false;
  isMenuOpen = false;
  searching = false;
  clickOnWrapper = new Subject<void>();
  readonly appVersion = packageInfo.version;

  constructor(
    private routerResolverService: RouterResolverBuilder,
    private routerEventsListener: RouterEventsListener,
    private routerEventsGAListener: RouterEventsGAListener,
    private router: Router,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    private eventService: EventsService,
    private utilisateurService: UtilisateurService,
    public entityStore: EntityStore,
    public entityFilterStatusStore: EntityFilterStatusStore
  ) {
    translate.onLangChange.pipe(pluck('lang'), startWith(translate.currentLang)).subscribe((lang) => {
      dateAdapter.setLocale(DATE_FNS_LOCALE_MAP.get(lang));
    });
  }

  ngOnInit() {
    this.routerResolverService
      .addResolvers({
        authenticatedUser: AuthenticatedUserResolver,
      })
      .applyTo(this.router);

    this.routerEventsListener.listen(this.router);
    this.routerEventsGAListener.listen(this.router);

    this.unreadEventsCount$ = this.eventService.getNumberOfUnreadEvent();
    this.utilisateurService.getConnectedUtilisateur().subscribe((user) => {
      if (!!user) {
        this.hasEntityFilterAccess = user.hasRole([Profil.ADMINISTRATEUR, Profil.OBSERVATEUR]);
        this.showEventsNotifications = user.habilitations.profil === Profil.ADMINISTRATEUR;
        this.eventService.countEvents(user.entite?.codeAti ?? null);
        this.entityStore.setEntity(user.entite);
      }
    });
  }

  onMenuStateChange(menuIsOpen: boolean): void {
    this.isMenuOpen = menuIsOpen;
  }

  ngOnDestroy() {
    this.routerEventsListener.clear();
    this.routerEventsGAListener.clear();
  }
}
