export enum FeatureFlippingKey {
  ALLOCATED_BALANCING = 'allocated-balancing',
  ALLOCATED_BALANCING_TETRA = 'allocated-balancing-tetra',
  CAPACITIES = 'capacities',
  CAPACITIES_TETRA = 'capacities-tetra',
  CAPACITY_REQUEST = 'capacity-request',
  COMMERCIAL_CONGESTION = 'commercial-congestion',
  COMMERCIAL_CONGESTION_TETRA = 'commercial-congestion-tetra',
  JOINT_DECLARATION_PIC = 'joint-declaration-pic',
  JOINT_DECLARATION_PITD = 'joint-declaration-pitd',
  NOMINATION_HELPER = 'nomination-helper',
  PROGRAMMING = 'programming',
  PROGRAMMING_TETRA = 'programming-tetra',
  SUBSCRIBE_PIC_CAPACITY = 'subscribe-pic-capacity',
  SUBSCRIBED_STORAGE_CAPACITY = 'subscribed-storage-capacity',
  SUBSCRIBED_STORAGE_CAPACITY_TETRA = 'subscribed-storage-capacity-tetra',
  SUBSCRIBED_TRANSPORT_CAPACITY = 'subscribed-transport-capacity',
  SUBSCRIBED_TRANSPORT_CAPACITY_TETRA = 'subscribed-transport-capacity-tetra',
  TRANSPORT_CESSIONS_PRIVATE = 'transport-cessions-private',
  TRANSPORT_CESSIONS_PUBLIC = 'transport-cessions-public',
  TRANSPORT_CESSIONS_TETRA = 'transport-cessions-tetra',
  SOBRIETY_PLAN = 'sobriety-plan',
  SOBRIETY_GUIDANCE = 'sobriety-guidance',
  CONTROL_DISTRIBUTION_CONSUMPTION = 'control-distribution-consumption',
  ECOGAZ = 'ecogaz',
  NEWSLETTER = 'newsletter',
  SIMULATORS = 'simulators',
  SHEDDING = 'shedding',
  GUARANTED_INTERRUPTIBILITY = 'guaranted-interruptibility',
  SECONDARY_INTERRUPTIBILITY = 'secondary-interruptibility',
  BUSINESS_ASSISTANCE = 'business-assistance',
  OPERATIONS_REGISTER_TETRA = 'operations-register-tetra',
  REQUESTS_STATUS_TETRA = 'request-status-tetra',
}
