import { InjectionToken } from '@angular/core';

export type AwsAuthDetails = {
  secretAccessKey: string;
  accessKeyId: string;
  sessionToken: string;
  awsRegion: string;
};

export const AWS_AUTH_DETAILS: InjectionToken<AwsAuthDetails> = new InjectionToken<AwsAuthDetails>('AWS_AUTH_DETAILS');
export const BASE_URI: InjectionToken<string> = new InjectionToken<string>('BASE_URI');

export type AuthModuleConfig = {
  authDetails: AwsAuthDetails;
  baseUri: string;
};
