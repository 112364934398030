<mat-card class="cms-slice__imagetext container mb-5">
  <div class="row no-gutters">
    <ng-template *ngIf="componentData?.fields.reversed; then imgBlock; else txtBlock"></ng-template>
    <ng-template *ngIf="componentData?.fields.reversed; then txtBlock; else imgBlock"></ng-template>
  </div>
</mat-card>


<ng-template #imgBlock>
  <div class="col-4" [ngClass]="{'order-last': componentData?.fields.reversed === 'yes'}">
    <cms-misc-front-img [componentData]="componentData?.fields.image"></cms-misc-front-img>
  </div>
 </ng-template>

<ng-template #txtBlock>
  <div class="col-8 p-5">
    <h2>{{componentData?.fields.title}}</h2>
    <cms-misc-document [componentData]="[componentData?.fields.content]"></cms-misc-document>
    <cms-misc-cta *ngIf="componentData?.fields.cta !== undefined"
                  [componentData]="componentData?.fields.cta"></cms-misc-cta>
  </div>
 </ng-template>
