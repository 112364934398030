import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entite } from '@terega-portal/shared-lib';
import { Observable } from 'rxjs';
import { URI } from '../../../environments/uri';

@Injectable({ providedIn: 'root' })
export class EntitiesService {
  constructor(private http: HttpClient) {}

  get(id: string): Observable<Entite> {
    return this.http.get<Entite>(URI.COMMON.GET_ENTITY(id));
  }

  getAll(): Observable<Entite[]> {
    return this.http.get<Entite[]>(URI.COMMON.ENTITY);
  }

  getAllExpediteur(): Observable<Entite[]> {
    const httpParams = new HttpParams({
      fromObject: {
        type_entite: 'EXPEDITEUR',
      },
    });
    return this.http.get<Entite[]>(URI.COMMON.ENTITY, { params: httpParams });
  }

  update(entite: Entite): Observable<Entite> {
    return this.http.put<Entite>(URI.COMMON.ENTITY, entite);
  }
}
