import { Injectable } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EntityFilterStatusStore } from '../entities/entity-filter-status.store';

@Injectable({ providedIn: 'root' })
export class RouterEventsListener {
  subscription: Subscription;

  constructor(private entityFilterStatusStore: EntityFilterStatusStore) {}

  listen(router: Router): void {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        const { enableEntityFilter } = event.snapshot.data;
        if (!!enableEntityFilter) {
          this.entityFilterStatusStore.enable();
        } else {
          this.entityFilterStatusStore.disable();
        }
      }
    });
  }

  clear(): void {
    this.subscription.unsubscribe();
  }
}
