<div [ngClass]="{'has-icon': backgroundIcon}" class="styled-dialog">
  <div class="styled-dialog__background" *ngIf="backgroundIcon">
    <mat-icon>{{backgroundIcon}}</mat-icon>
  </div>
  <div class="styled-dialog__header">
    <h1 mat-dialog-title>{{dialogTitle | translate}}</h1>
    <button mat-icon-button [mat-dialog-close]="false" class="styled-dialog__close"><mat-icon>close</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <ng-content select="[dialogContent]"></ng-content>
  </div>
  <div *ngIf="!!actions.length" mat-dialog-actions>
    <ng-content select="[dialogAction]"></ng-content>
  </div>
</div>
