import { Menu } from '../menu.model';
import { Profil } from '@terega-portal/auth-lib';

export const USEFUL_LINKS_MENU: Menu = {
  name: 'USEFUL_LINKS',
  icon: 'useful-links',
  items: [
    {
      name: '',
      items: [
        { name: 'STOREPLACE', externalLink: 'https://storeplace.terega.fr/' },
        { name: 'PRISMA', link: '/edito/prisma-la-plateforme-de-reservation-de-capacites-de-transport-de-gaz' },
        { name: 'POWERNEXT', externalLink: 'https://www.powernext.com/' },
        { name: 'CRE', externalLink: 'https://www.cre.fr' },
        { name: 'ACER', externalLink: 'https://acer.europa.eu/gas/about-gas' },
        { name: 'CONCERTATION_GAZ', externalLink: 'https://www.concertationgaz.com/site/home' },
        { name: 'ENTSOG', externalLink: 'https://www.entsog.eu' },
        { name: 'CONCERTATION_STOCKAGE', externalLink: 'https://www.concertationstockage.com/' },
        {
          name: 'TOOLBOX_SECONDARY_MARKET',
          externalLink: '/star-app/offre/marche-secondaire',
          authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.CLIENT, Profil.OBSERVATEUR, Profil.PROSPECT],
        },
      ],
    },
  ],
};
