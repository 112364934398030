import { BreadcrumbMap } from './breadcrumb.service';

export const BREADCRUMB_OUTSIDE_MENU: BreadcrumbMap = {
  ACCOUNT_ACTIVATE: {
    breadcrumb: ['ACCOUNT_ACTIVATE'],
  },
  CGU_TITLE: {
    breadcrumb: ['CGU_TITLE'],
    value: {
      name: 'CGU_TITLE',
      externalLink: '/conditions-generales-utilisation',
    },
  },
  CONNEXION: {
    breadcrumb: ['CONNEXION'],
  },
  EVENTS: {
    breadcrumb: ['EVENTS'],
    value: {
      name: 'EVENTS',
      externalLink: '/conditions-generales-utilisation',
    },
  },
  INSCRIPTION: {
    breadcrumb: ['INSCRIPTION'],
  },
  OUR_API: {
    breadcrumb: ['OUR_API'],
    value: {
      name: 'OUR_API',
      link: '/our-api',
    },
  },
  RESET_PASSWORD: {
    breadcrumb: ['RESET_PASSWORD'],
  },
  UPDATE_PASSWORD: {
    breadcrumb: ['UPDATE_PASSWORD'],
  },
  WELCOME: {
    breadcrumb: ['WELCOME'],
  },
};
