import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Utilisateur, UtilisateurService } from '@terega-portal/auth-lib';
import { StarEventBroadcasterService, StarEventType, TypeEntite } from '@terega-portal/shared-lib';
import { Subscription } from 'rxjs';

@Component({
  selector: 'star-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss'],
})
export class HeaderUserComponent implements OnInit, OnDestroy {
  @Input() ignoreNode;
  user: Utilisateur;
  userChangedSubscription: Subscription;
  activeMenu = false;
  typeEntite = TypeEntite.EXPEDITEUR;

  constructor(public utilisateurService: UtilisateurService, private starEventBroadcaster: StarEventBroadcasterService) {}

  ngOnInit(): void {
    this.utilisateurService.estConnecte();
    this.initUser();
    this.userChangedSubscription = this.starEventBroadcaster.listen(StarEventType.USER_CHANGED).subscribe(() => this.initUser());
  }

  ngOnDestroy(): void {
    this.userChangedSubscription.unsubscribe();
  }

  private initUser(): void {
    this.utilisateurService.getConnectedUtilisateur().subscribe((user) => (this.user = user));
  }

  onSubMenuClick(): void {
    if (this.user) {
      this.toggleUserSubMenu();
    } else {
      window.location.href = '/star-app/connexion';
    }
  }

  toggleUserSubMenu(): void {
    this.activeMenu = !this.activeMenu;
  }

  closeUserSubMenu(): void {
    this.activeMenu = false;
  }

  @HostListener('document:click', ['$event'])
  hideUserSubMenu(event: Event) {
    if (event.target) {
      let parent = event.target as Element;
      while (parent) {
        if (parent.nodeName === this.ignoreNode) {
          // Ne pas fermer le menu lors d'un click dans le popup
          return;
        }
        parent = parent.parentNode as Element;
      }
      // Fermer le menu lors d'un click en dehors du popup
      this.closeUserSubMenu();
    }
  }
}
