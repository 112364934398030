import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationExtras } from '@angular/router';
import { StarRouterService } from './star-router.service';

@Injectable({
  providedIn: 'root',
})
export class AngularRouterService implements StarRouterService {
  constructor(private router: Router) {}

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras);
  }
}
