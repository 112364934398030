import { Menu } from '../menu.model';
import { Profil, Delegation } from '@terega-portal/auth-lib';
import { FeatureFlippingKey } from '@terega-portal/shared-lib';

export const MY_DATA_MENU: Menu = {
  name: 'MY_DATA',
  icon: 'my-data',
  authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.CLIENT_RACCORDE, Profil.OBSERVATEUR, Profil.CLIENT],
  items: [
    {
      name: 'GUIDANCE',
      authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
      items: [
        {
          name: 'GETTING_STARTED_GUIDE',
          resourceLink: {
            en: '/resources/guides/starting/getting_started_guide_EN-V1.6.pdf',
            fr: '/resources/guides/starting/getting_started_guide_FR-V1.6.pdf',
          },
        },
      ],
    },
    {
      name: 'CONTRACTS_SERVICES',
      authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
      items: [{ name: 'CONTRACTS_SERVICES', externalLink: '/star-app/offre/contrats-et-services' }],
    },
    {
      name: 'SUBSCRIPTIONS',
      authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT, Profil.CLIENT_RACCORDE],
      authorizedDelegations: [Delegation.DELEGATION_OFFRE_TRANSPORT],
      items: [
        {
          name: 'JOINT_DECLARATION',
          toggle: true,
          items: [
            {
              name: 'JOINT_DECLARATION_PIC',
              link: '/logistique/joint-declaration/pic',
              featureFlippingKey: FeatureFlippingKey.JOINT_DECLARATION_PIC,
            },
            {
              name: 'JOINT_DECLARATION_PITD',
              link: '/logistique/joint-declaration/pitd',
              featureFlippingKey: FeatureFlippingKey.JOINT_DECLARATION_PITD,
            },
            {
              name: 'OPERATIONS_REGISTER_TETRA',
              tetraLink: 'DeclarationConjointeJournal.do?action=journal',
              featureFlippingKey: FeatureFlippingKey.OPERATIONS_REGISTER_TETRA,
            },
          ],
          authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
        },
        {
          name: 'CAPACITY_REQUEST',
          link: '/offer/capacity-request',
          featureFlippingKey: FeatureFlippingKey.CAPACITY_REQUEST,
          authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT, Profil.CLIENT_RACCORDE],
          authorizedDelegations: [Delegation.DELEGATION_OFFRE_TRANSPORT],
        },
        {
          name: 'TRANSPORT_ALLOCATIONS_TETRA',
          tetraLink: 'SuiviOperationnelEquilibrage.do?action=liste',
          featureFlippingKey: FeatureFlippingKey.ALLOCATED_BALANCING_TETRA,
          authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
        },
        {
          name: 'SUBSCRIBED_TRANSPORT_CAPACITY',
          link: '/offer/subscribed-transport-capacity',
          featureFlippingKey: FeatureFlippingKey.SUBSCRIBED_TRANSPORT_CAPACITY,
          authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
        },
        {
          name: 'REQUESTS_STATUS_TETRA',
          tetraLink: 'ListeTraitement.do?action=liste',
          featureFlippingKey: FeatureFlippingKey.REQUESTS_STATUS_TETRA,
          authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
        },
        {
          name: 'STANDARDIZED_CAPACITIES',
          link: '/offer/transport-capacities/standardized-capacities',
          authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
        },
        {
          name: 'TRANSPORT_CESSIONS_TETRA',
          tetraLink: 'ListeJournalCession.do?action=listeCession',
          featureFlippingKey: FeatureFlippingKey.TRANSPORT_CESSIONS_TETRA,
          authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
        },
        {
          name: 'TRANSPORT_CESSIONS_PRIVATE',
          link: '/offer/transport-cessions/private',
          featureFlippingKey: FeatureFlippingKey.TRANSPORT_CESSIONS_PRIVATE,
          authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
        },
        {
          name: 'STORAGE_PORFOLIO_TETRA',
          tetraLink: 'PortefeuilleStockage.do?action=liste',
          featureFlippingKey: FeatureFlippingKey.SUBSCRIBED_STORAGE_CAPACITY_TETRA,
          authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
        },
        {
          name: 'SUBSCRIBED_STORAGE_CAPACITY',
          link: '/offer/subscribed-storage-capacity',
          featureFlippingKey: FeatureFlippingKey.SUBSCRIBED_STORAGE_CAPACITY,
          authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
        },
        {
          name: 'BOOSTER_INJECTION',
          externalLink: '/star-app/offre/booster-injection',
          authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
        },
      ],
    },
    {
      name: 'OPERATIONAL_MONITORING',
      authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
      items: [
        { name: 'LOGISTICIAN_DASHBOARD', externalLink: '/star-app/logistique/tableau-de-bord' },
        {
          name: 'NOMINATION_HELPER',
          link: '/logistique/nomination-helper',
          featureFlippingKey: FeatureFlippingKey.NOMINATION_HELPER,
        },
        {
          name: 'PROGRAMMING_TETRA',
          tetraLink: 'SuiviOperationnelProgrammation.do?action=liste',
          featureFlippingKey: FeatureFlippingKey.PROGRAMMING_TETRA,
        },
        { name: 'PROGRAMMING', link: '/logistique/programming', featureFlippingKey: FeatureFlippingKey.PROGRAMMING },
        {
          name: 'STORAGE_OPERATIONAL_MONITORING',
          link: '/logistique/storage-operational-monitoring',
        },
        { name: 'PRIVATE_INTRADAY_DATA', link: '/logistique/intra-j/private' },
        {
          name: 'PRIVATE_PITD_FORECAST',
          link: '/logistique/forecast/pitd',
        },
        { name: 'TRF_OPERATIONAL_CAPACITIES', link: '/logistique/trf-capacities' },
        {
          name: 'ALLOCATED_BALANCING',
          link: '/logistique/allocated-balancing',
          featureFlippingKey: FeatureFlippingKey.ALLOCATED_BALANCING,
        },
      ],
    },
    {
      name: 'BILLING',
      authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT],
      items: [
        { name: 'BILLING_DASHBOARD', externalLink: '/star-app/facturation/tableau-de-bord' },
        { name: 'BILLING_DOCUMENTS', externalLink: '/star-app/facturation/documents' },
        { name: 'IMBALANCE_SIMULATOR', externalLink: '/star-app/facturation/simulateur' },
      ],
    },
    {
      name: 'CONNECTION',
      authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT_RACCORDE],
      items: [
        { name: 'CONTRACTS_INVOICES', externalLink: '/star-app/connection/contracts-invoices' },
        {
          name: 'CONTROL_DISTRIBUTION_CONSUMPTION',
          link: '/connection/control-distribution-consumption',
          featureFlippingKey: FeatureFlippingKey.CONTROL_DISTRIBUTION_CONSUMPTION,
        },
        { name: 'CONSUMPTION_DATA', link: '/connection/consumption-data' },
        {
          name: 'WINTER_MODULATION',
          link: '/connection/winter-modulation',
        },
        { name: 'GAS_QUALITY', externalLink: '/star-app/connection/gas-quality' },
        {
          name: 'SUBSCRIBE_PIC_CAPACITY',
          externalLink: '/star-app/connection/subscribe-pic-capacity',
          featureFlippingKey: FeatureFlippingKey.SUBSCRIBE_PIC_CAPACITY,
          authorizedProfiles: [Profil.ADMINISTRATEUR, Profil.OBSERVATEUR, Profil.CLIENT_RACCORDE],
        },
      ],
    },
  ],
};
