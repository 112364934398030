import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { childTranslate } from './child.translate';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { MailValidatorDirective } from './customValidator/mailValidator';
import { StarEventBroadcasterService } from './event-broadcaster/star-event-broadcaster.service';
import { LocalStorageStarService } from './localstorage/localstorage.service';
import { MessageService } from './message/message.service';
import { PatternRestrictDirective } from './pattern/pattern-restrict.directive';
import { StarRouterLinkDirective } from './router/star-router-link.directive';
import { BASE_URI, SharedModuleConfig } from './shared.type';
import { SsoLinkComponent } from './sso-link/sso-link.component';
import { SsoService } from './sso-link/sso-link.service';

@NgModule({
  declarations: [MailValidatorDirective, PatternRestrictDirective, ConfirmPopupComponent, StarRouterLinkDirective, SsoLinkComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    NgxWebstorageModule.forRoot({ prefix: '', separator: '' }),
    TranslateModule.forChild(childTranslate),
  ],
  exports: [ConfirmPopupComponent, MailValidatorDirective, PatternRestrictDirective, StarRouterLinkDirective, SsoLinkComponent],
  providers: [MailValidatorDirective, LocalStorageStarService, MessageService, TranslateService, StarEventBroadcasterService, SsoService],
})
export class StarSharedModule {
  public static forRoot(config: SharedModuleConfig): ModuleWithProviders<StarSharedModule> {
    return {
      ngModule: StarSharedModule,
      providers: [{ provide: BASE_URI, useValue: config.baseUri }],
    };
  }
}
