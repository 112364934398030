import { CommonModule } from '@angular/common';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthModule, UtilisateurService } from '@terega-portal/auth-lib';
import { MultiAppRouterService, StarRouterService, StarSharedModule } from '@terega-portal/shared-lib';
import { CollapsibleRowComponent } from './collapsible-row/collapsible-row.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConsentBarComponent } from './consent-bar/consent-bar.component';
import { CustomIconService } from './custom-icon/custom-icon.service';
import { FlippableDirective } from './flippable/flippable.directive';
import { FooterComponent } from './footer/footer.component';
import { HeaderHourComponent } from './header/header-hour/header-hour.component';
import { HeaderLangComponent } from './header/header-lang/header-lang.component';
import { HeaderUserSubMenuComponent } from './header/header-user/header-user-sub-menu.component';
import { HeaderUserComponent } from './header/header-user/header-user.component';
import { HeaderComponent } from './header/header.component';
import { NotificationComponent } from './notification/notification.component';
import { PageComponent } from './page/page.component';
import { SelectComponent } from './select/select.component';
import { MenuComponent } from './menu/menu.component';
import { MenuService } from './menu/menu.service';
import { IsAllowedDirective } from './menu/is-allowed.directive';
import { IsAllowedDelegationDirective } from './menu/is-allowed-delegation.directive';
import { ExternalLinkDirective } from './menu/external-link.directive';
import { StyledDialogComponent } from './styled-dialog/styled-dialog.component';
import { MatOptionModule } from '@angular/material/core';
import { BASE_URI, TETRA_LINK, UiModuleConfig } from './ui.type';
import { TetraLinkPipe } from './pipe/tetra-link.pipe';
import { MenuSearchBarComponent } from './menu-search-bar/menu-search-bar.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderHourComponent,
    HeaderUserComponent,
    HeaderLangComponent,
    HeaderUserSubMenuComponent,
    FooterComponent,
    FlippableDirective,
    ConsentBarComponent,
    CollapsibleRowComponent,
    NotificationComponent,
    StyledDialogComponent,
    ConfirmDialogComponent,
    PageComponent,
    SelectComponent,
    MenuComponent,
    IsAllowedDirective,
    ExternalLinkDirective,
    TetraLinkPipe,
    MenuSearchBarComponent,
    IsAllowedDelegationDirective,
  ],
  imports: [
    CommonModule,
    AuthModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSelectModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    StarSharedModule,
    TranslateModule,
    RouterModule,
    MatCardModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    FlippableDirective,
    ConsentBarComponent,
    CollapsibleRowComponent,
    NotificationComponent,
    StyledDialogComponent,
    ConfirmDialogComponent,
    PageComponent,
    SelectComponent,
    MenuComponent,
    MenuSearchBarComponent,
  ],
  providers: [
    { provide: StarRouterService, useClass: MultiAppRouterService },
    { provide: LOCALE_ID, useValue: 'fr' },
    CustomIconService,
    TranslateService,
    UtilisateurService,
    MenuService,
    TetraLinkPipe,
  ],
})
export class UiModule {
  public static forRoot(config: UiModuleConfig): ModuleWithProviders<UiModule> {
    return {
      ngModule: UiModule,
      providers: [
        { provide: BASE_URI, useValue: config.baseUri },
        { provide: TETRA_LINK, useValue: config.tetraLink },
      ],
    };
  }
}
