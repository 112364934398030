import { Menu } from '../menu.model';
import { Profil } from '@terega-portal/auth-lib';

export const ADMINISTRATION_MENU: Menu = {
  name: 'ADMIN',
  icon: 'admin',
  authorizedProfiles: [Profil.ADMINISTRATEUR],
  items: [
    {
      name: '',
      items: [
        { name: 'ENTITY_MANAGEMENT', externalLink: '/star-app/administration/entites' },
        { name: 'USER_MANAGEMENT', externalLink: '/star-app/administration/users' },
        { name: 'MAINTENANCE_MANAGEMENT', link: 'administration/scheduled-maintenance' },
      ],
    },
  ],
};
