export interface CMSComponent {
  componentData: CMSComponentData;
}

export interface CMSComponentData {
  fields: any;
  sys: any;
}

export enum ContentType {
  PAGE = 'page',
  IMAGE_TEXT = 'imageTexteSection',
  FULL_TEXT = 'fullText',
  VIDEO = 'video',
}
