import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CMSEditoLoaderComponent } from './shared/cms/components/cms-edito-loader/cms-edito-loader.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./accueil/accueil.module').then((m) => m.AccueilModule), pathMatch: 'full' },
  { path: 'logistique', loadChildren: () => import('./operations/operations.module').then((m) => m.OperationsModule) },
  { path: 'offer', loadChildren: () => import('./offer/offer.module').then((m) => m.OfferModule) },
  { path: 'our-api', loadChildren: () => import('./our-api/our-api.module').then((m) => m.OurApiModule) },
  { path: 'connection', loadChildren: () => import('./connection/connection.module').then((m) => m.ConnectionModule) },
  {
    path: 'edito/:slug',
    component: CMSEditoLoaderComponent,
  },
  { path: 'administration', loadChildren: () => import('./administration/administration.module').then((m) => m.AdministrationModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  providers: [{ provide: APP_BASE_HREF, useValue: '/app' }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
