import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Delegation, Profil } from '@terega-portal/auth-lib';
import { MenuService } from './menu.service';

@Directive({
  selector: '[starIsAllowedDelegation]',
})
export class IsAllowedDelegationDirective {
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private menuService: MenuService) {}

  @Input() set starIsAllowedDelegation(authorizedDelegations: Delegation[]) {
    if (!authorizedDelegations) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.menuService.getConnectedUser().subscribe((user) => {
        const userDelegations = [
          user.habilitations.hasDelegationOffreStockage ? Delegation.DELEGATION_OFFRE_STOCKAGE : null,
          user.habilitations.hasDelegationOffreTransport ? Delegation.DELEGATION_OFFRE_TRANSPORT : null,
        ];
        if (
          user &&
          (authorizedDelegations.some((delegation) => userDelegations.includes(delegation)) || !user.hasRole([Profil.CLIENT_RACCORDE]))
        ) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
    }
  }
}
