export const environment = {
  production: false,
  environment: 'integration',
  apiUrl: 'https://da9eh6rkld.execute-api.eu-west-1.amazonaws.com/integration/v1',
  cloudFront: 'https://d14jsn6jjdx906.cloudfront.net/resources',
  googleAnalyticsId: 'UA-131266439-4',
  authDefaults: {
    secretAccessKey: '',
    accessKeyId: '',
    sessionToken: '',
    awsRegion: '',
  },
  cms: {
    distributionChannel: '1I2JmDk7vmZDzuCXL4dKTY', // only CMS contents with this channel are authorized for Portail Star
    spaceId: 'ztehsn2qe34u',
    apiKey: 'M-cLt2u-V_V0FRRE_PVl8_WYjEZOYDMGjfzuCw21wjI',
    basepath: location.origin + '/star-app/edito/',
  },
  teregaLink: 'https://www.terega.fr/',
  teregaLinkEn: 'https://www.terega.fr/en/',
  tetraBaseHref: 'https://integ.tetra.tigf.fr/SBT',
  prismaBaseHref: 'https://platform.prisma-capacity.eu',
  timezone: 'Europe/Paris',
  captcha: {
    siteKey: '6LefhqwUAAAAABlXdSY_fh2GGaPSbjmdCRmdSSU8',
  },
};
