import { Utilisateur } from '../user/utilisateur';

export enum StageUser {
  Creation = 1,
  Activer,
  Inscription,
  Modifier,
}

export class UtilisateurForm extends Utilisateur {
  acceptClause: boolean;
  motDePasse: string;
  confirmeMotDePasse: string;
  captchaResponse: string;
  role: string;
}
