import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BASE_URI } from '../shared.type';

export type RecursiveBoolean = {
  [k: string]: boolean | RecursiveBoolean;
};

export type FeatureFlags = RecursiveBoolean;

@Injectable({ providedIn: 'root' })
export class FeatureFlippingService {
  private _flags: FeatureFlags = {};
  constructor(@Inject(BASE_URI) private baseUri: string, private httpClient: HttpClient) {}

  get flags(): FeatureFlags {
    return this._flags;
  }

  get(key: string): boolean | void {
    const keyValue = key && this._flags ? this.findprop(this._flags, key) : undefined;
    if (typeof keyValue === 'boolean') {
      return keyValue;
    }
  }

  init(): Promise<FeatureFlags> {
    return this.httpClient
      .get<FeatureFlags>(`${this.baseUri}/configuration/feature-flags?d=${Date.now()}`)
      .pipe(
        tap((response: FeatureFlags) => {
          this._flags = response;
        }),
        catchError((err) => {
          console.error('Configuration not loaded');
          return of(this._flags);
        })
      )
      .toPromise();
  }

  /**
   * get a property in an object, with n levels allowed
   * @param obj
   * @param path
   */
  findprop(obj, path) {
    if (!obj || !path) {
      return;
    }
    const args = path.split('.');
    let i = 0,
      l = 0;

    for (i = 0, l = args.length; i < l; i++) {
      if (!obj.hasOwnProperty(args[i])) {
        return;
      }
      obj = obj[args[i]];
    }
    return obj;
  }
}

export function featureFlippingProviderFactory(service: FeatureFlippingService) {
  return (): Promise<FeatureFlags> => service.init();
}
