import { Injectable, Type } from '@angular/core';
import * as contentful from 'contentful';
import { CMSComponentData, ContentType } from '../models/cms.model';
import { TranslateService } from '@ngx-translate/core';
import { CMSPageComponent } from '../components/cms-page/cms-page.component';
import { CMSSliceImageTextComponent } from '../components/cms-slices/slice-imagetext/cms-slice-imagetext.component';
import { CMSSliceFullTextComponent } from '../components/cms-slices/slice-fulltext/cms-slice-fulltext.component';
import { CMSSliceVideoComponent } from '../components/cms-slices/slice-video/cms-slice-video.component';
import { environment } from '../../../../environments/environment';
import { from, Observable } from 'rxjs';

const COMPONENT_BY_TYPES: Map<ContentType, Type<any>> = new Map<ContentType, Type<any>>([
  [ContentType.PAGE, CMSPageComponent],
  [ContentType.IMAGE_TEXT, CMSSliceImageTextComponent],
  [ContentType.FULL_TEXT, CMSSliceFullTextComponent],
  [ContentType.VIDEO, CMSSliceVideoComponent],
]);
const DEFAULT_LANGUAGE = 'fr';
const CONTENTFULL_ABSOLUTE_URL_PREFIX = 'https://portail.terega.fr/star-app/edito/';

@Injectable()
export class CMSResolverService {
  constructor(private translateService: TranslateService) {}

  private readonly cmsClient: contentful.ContentfulClientApi = contentful.createClient({
    space: environment.cms.spaceId,
    accessToken: environment.cms.apiKey,
  });

  public async retrieveContent(contentId: string): Promise<CMSComponentData> {
    try {
      const cmsRequestParameters = this.buildCmsRequestParameters(contentId, this.translateService.currentLang);
      let cmsContent = await this.cmsClient.getEntries(cmsRequestParameters);

      if (!cmsContent.items.length && this.translateService.currentLang !== DEFAULT_LANGUAGE) {
        console.info(
          `CMS content not available with requested language '${this.translateService.currentLang}'. Get content in default language '${DEFAULT_LANGUAGE}'`
        );
        const cmsRequestParameters = this.buildCmsRequestParameters(contentId, DEFAULT_LANGUAGE);
        cmsContent = await this.cmsClient.getEntries(cmsRequestParameters);
      }
      return Promise.resolve(cmsContent.items[0]);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  private buildCmsRequestParameters(contentId: string, language: string) {
    return {
      'fields.absoluteUrl': CONTENTFULL_ABSOLUTE_URL_PREFIX + contentId,
      'fields.distributionChannel.sys.id[in]': environment.cms.distributionChannel,
      include: 10,
      content_type: ContentType.PAGE,
      locale: language,
    };
  }

  public resolveComponent(contentType: ContentType): Type<any> {
    const component: Type<any> = COMPONENT_BY_TYPES.get(contentType);

    if (!component) {
      return null;
    }
    return component;
  }

  public getAssetById(id: string): Observable<contentful.Asset> {
    return from(this.cmsClient.getAsset(id, { locale: this.translateService.currentLang }));
  }
}
