import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ExternalLink, TetraLink } from './menu.model';
import { MenuService } from './menu.service';

@Directive({
  selector: '[starExternalLink]',
})
export class ExternalLinkDirective {
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private menuService: MenuService) {}

  @Input() set starExternalLink(link: TetraLink | ExternalLink) {
    if (link['externalLink']) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }

    if (link['tetraLink']) {
      const tetraLink = link['tetraLink'];
      this.menuService.isTetraLinkAllowed(tetraLink).subscribe((isAllowed: boolean) => {
        if (isAllowed) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (tetraLink.indexOf('public') === 0) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
    }
  }
}
