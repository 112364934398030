import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { StarRouterService } from './star-router.service';

declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class MultiAppRouterService implements StarRouterService {
  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    if (!commands || commands.length === 0) {
      return Promise.resolve(false);
    }

    const queryParamString = deduceQueryParams(extras);
    window.location.href = `${commands[0]}${queryParamString}`;
    return Promise.resolve(true);
  }
}

function deduceQueryParams(extras): string {
  if (extras && extras.queryParams) {
    const searchParams = new URLSearchParams();
    Object.keys(extras.queryParams).forEach((key) => searchParams.append(key, extras.queryParams[key]));
    return extras && extras.queryParams ? `?${searchParams}` : '';
  } else {
    return '';
  }
}
