import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { StarEventBroadcasterService, StarEventType } from '@terega-portal/shared-lib';
import { UtilisateurService } from '../user/utilisateur.service';
import { Utilisateur } from '../user/utilisateur';
import { Delegation } from '../auth/services/models/delegation.enum';

/**
 * ## Description :
 * AccessAuthorized permet d'inclure un template HTML en fonction des droits de l'utilisateur courant par application.
 *
 * AccessAuthorized se base sur le fonctionnement de la directive *ngIf et évalue l'expression passée en paramètre
 * en fonction de l'utilisateur courant
 *
 * AccessAuthorized détecte le changement d'utilisateur (connexion / déconnexion...) et mets à jour son template si le
 * nouvel utilisateur ne possède plus les droits d'affichage sur le template concerné. *
 **/
export class AccessAuthorizedContext {
  public $implicit: any = null;
  public accessAuthorized: any = null;
}
@Directive({
  selector: '[accessAuthorized]',
})
export class AccessAuthorizedDirective implements OnDestroy, OnInit {
  private userChangedSubscription: Subscription;
  private requiredDelegations: Delegation[];

  private context: AccessAuthorizedContext = new AccessAuthorizedContext();

  @Input() set accessAuthorized(value: { requiredDelegations?: Delegation[] }) {
    if (!value || value.requiredDelegations) {
      throw new Error('Missing parameters application: profils or requiredDelegations');
    }

    if (value.requiredDelegations && value.requiredDelegations.length && value.requiredDelegations.find((deleg) => !Delegation[deleg])) {
      const delegationNamesDisplay = value.requiredDelegations.join(',');
      throw new Error('Bad parameter requiredDelegations, check if ' + delegationNamesDisplay + ' are in Delegation enum');
    }

    this.context.$implicit = this.context.accessAuthorized = value;

    this.requiredDelegations = value.requiredDelegations;
    this.update();
  }

  constructor(
    private starEventBroadcaster: StarEventBroadcasterService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<AccessAuthorizedContext>,
    private utilisateurService: UtilisateurService
  ) {}

  ngOnInit() {
    this.userChangedSubscription = this.starEventBroadcaster.listen(StarEventType.USER_CHANGED).subscribe(() => this.update());
  }

  private update() {
    this.utilisateurService.getConnectedUtilisateur().subscribe((user) => {
      if (this.checkUserAccess(user)) {
        this.viewContainer.createEmbeddedView(this.templateRef, this.context);
      } else {
        this.viewContainer.clear();
      }
    });
  }

  private checkUserAccess(user: Utilisateur) {
    return (
      this.requiredDelegations &&
      user &&
      this.requiredDelegations.every((deleg: Delegation) => this.utilisateurService.hasDelegation(deleg))
    );
  }

  ngOnDestroy(): void {
    if (this.userChangedSubscription) {
      this.userChangedSubscription.unsubscribe();
    }
  }
}
