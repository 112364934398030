import { MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { MatDateFormats } from '@angular/material/core';

/**
 * Custom format of read and showed date/time in the components Angular Material
 * Used for configuration format date of Angular Material: MAT_DATE_FORMATS
 */
export const PORTAIL_MAT_DATE_FNS_FORMATS: MatDateFormats = {
  parse: {
    ...MAT_DATE_FNS_FORMATS.parse,
    dateInput: 'dd/MM/yyyy',
  },
  display: {
    ...MAT_DATE_FNS_FORMATS.display,
    dateInput: 'dd/MM/yyyy',
  },
};
