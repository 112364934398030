import { Locale } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';

/**
 * Map a string with object local of date-fns
 * Used for configuration locale of Angular Material: MAT_DATE_LOCALE
 */
export const DATE_FNS_LOCALE_MAP: Map<string, Locale> = new Map([
  ['fr', fr],
  ['en', enUS],
]);
