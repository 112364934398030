import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'star-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ConfirmPopupComponent {
  public static CONFIRM = 'Confirm';
  public static CANCEL = 'Cancel';

  message: string;
  buttontCancelLabel: string;
  buttontConfirmLabel: string;

  constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    this.buttontCancelLabel = data.buttontCancelLabel;
    this.buttontConfirmLabel = data.buttontConfirmLabel;
  }

  onClickCancel() {
    this.dialogRef.close(ConfirmPopupComponent.CANCEL);
  }

  onClickConfirm() {
    this.dialogRef.close(ConfirmPopupComponent.CONFIRM);
  }
}
