import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Entite } from '@terega-portal/shared-lib';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EntitiesService } from '../entities.service';
import { EntityStore } from '../entity.store';

@Component({
  selector: 'app-entity-filter',
  templateUrl: './entity-filter.component.html',
  styleUrls: ['./entity-filter.component.scss'],
})
export class EntityFilterComponent implements OnInit, OnDestroy {
  entities: Entite[] = [];
  entitiesSubscription: Subscription;
  entityControl = new FormControl();
  filteredOptions: Observable<Entite[]>;

  constructor(public entitiesService: EntitiesService, private entityStore: EntityStore) {}

  ngOnInit(): void {
    this.entitiesSubscription = this.entitiesService.getAllExpediteur().subscribe((entities) => (this.entities = entities));

    this.filteredOptions = this.entityControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.nom)),
      map((name) => (name ? this._filter(name) : this.entities.slice()))
    );
  }

  displayFn(entity: Entite): string {
    return entity?.nom || '';
  }

  private _filter(name: string): Entite[] {
    return this.entities.filter((option) => option.nom.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  public optionSelected(event: MatAutocompleteSelectedEvent): void {
    this.entityStore.setEntity(event.option.value);
  }

  ngOnDestroy(): void {
    this.entitiesSubscription.unsubscribe();
  }
}
