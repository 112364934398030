import { AbstractEventDetails } from './abstract-event-details.model';

/**
 * event details for type PIC_DAILY_CAPACITY_OVERRUN
 */
export class PicDailyCapacityOverrunEventDetails extends AbstractEventDetails {
  constructor() {
    super();
  }
}
