import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { APP_BASE_HREF } from '@angular/common';

export function createTranslateLoader(http: HttpClient, baseHref: string) {
  return new TranslateHttpLoader(http, `${baseHref}/assets/i18n/`, '.json?d=' + Date.now());
}

export const appTranslate: any = {
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient, APP_BASE_HREF],
  },
  useDefaultLang: false,
};
