import { Menu } from '../menu.model';
import { FeatureFlippingKey } from '@terega-portal/shared-lib';

export const CONNECTION_MENU: Menu = {
  name: 'CONNECTION',
  icon: 'connection',
  items: [
    {
      name: 'OFFER',
      items: [
        { name: 'CONTRACTUAL_INFORMATION_CONNECTION', link: '/edito/informations-contractuelles-raccordement' },
        {
          name: 'CONNECTION_COMMERCIAL_OFFER_BOOKLET',
          resourceLink: {
            en: '/resources/offers/offre_raccordement_fr.pdf',
            fr: '/resources/offers/offre_raccordement_fr.pdf',
          },
        },
        { name: 'OUR_SERVICE', link: '/edito/services-et-accompagnement-raccordement' },
        {
          name: 'SOBRIETY_GUIDANCE',
          link: '/edito/accompagnement-a-la-sobriete',
          featureFlippingKey: FeatureFlippingKey.SOBRIETY_GUIDANCE,
        },
      ],
    },
    {
      name: 'OPERATIONAL_DATA',
      items: [{ name: 'CO2_EMISSIONS', link: '/edito/emissionsCO2' }],
    },
    {
      name: 'DOCUMENTATIONS_TOOLS',
      items: [
        { name: 'ECOGAZ', link: '/edito/ecogaz', featureFlippingKey: FeatureFlippingKey.ECOGAZ },
        { name: 'NEWSLETTER', link: '/edito/newsletter', featureFlippingKey: FeatureFlippingKey.NEWSLETTER },
        {
          name: 'SIMULATORS',
          link: '/edito/terega-propose-des-outils-de-simulation-tarifaire',
          featureFlippingKey: FeatureFlippingKey.SIMULATORS,
        },
      ],
    },
    {
      name: 'REGULATORY_INFORMATION',
      items: [
        { name: 'SOBRIETY_PLAN', link: '/edito/plan-de-sobriete', featureFlippingKey: FeatureFlippingKey.SOBRIETY_PLAN },
        { name: 'BUSINESS_ASSISTANCE', link: '/edito/aides-aux-entreprises', featureFlippingKey: FeatureFlippingKey.BUSINESS_ASSISTANCE },
        { name: 'CONSUMPTION_DISTRIBUTION', link: '/edito/repartition-consommation' },

        {
          name: 'GUARANTED_INTERRUPTIBILITY',
          link: '/edito/interruptibilite-garantie',
          featureFlippingKey: FeatureFlippingKey.GUARANTED_INTERRUPTIBILITY,
        },
        {
          name: 'SECONDARY_INTERRUPTIBILITY',
          link: '/edito/interruptibilite-secondaire',
          featureFlippingKey: FeatureFlippingKey.SECONDARY_INTERRUPTIBILITY,
        },
        { name: 'SHEDDING', link: '/edito/delestage', featureFlippingKey: FeatureFlippingKey.SHEDDING },
      ],
    },
  ],
};
