import { Directive, HostListener, Input } from '@angular/core';
import { StarRouterService } from './star-router.service';

@Directive({
  selector: '[starRouterLink]',
})
export class StarRouterLinkDirective {
  @Input() public starRouterLink: string;
  @Input() public targetRouterLink: string;

  constructor(private starRouterService: StarRouterService) {}

  @HostListener('click', ['$event']) onClick($event: Event) {
    if ($event) {
      $event.stopPropagation();
      $event.preventDefault();
    }
    if (!this.targetRouterLink) {
      this.starRouterService.navigate([this.starRouterLink]);
    } else {
      switch (this.targetRouterLink) {
        case '_blank':
          const url = window.location.origin + this.starRouterLink;
          window.open(url, '', 'noopener');
          break;
        default:
          break;
      }
    }
  }
}
