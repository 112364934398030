import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageStarService } from '@terega-portal/shared-lib';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, pluck, switchMap, tap } from 'rxjs/operators';
import { BASE_URI } from '../auth.type';
import { AuthService } from '../auth/services/auth.service';
import { Delegation } from '../auth/services/models/delegation.enum';
import { Profil } from '../auth/services/models/user-profiles.enum';
import { AskPasswordReset } from '../shared/ask-password-reset.model';
import { ChangeMotDePasseParameters } from '../shared/change-mot-de-passe-parameters';
import { NouvelUtilisateur } from '../shared/nouvelutilisateur';
import { SendPasswordReset } from '../shared/send-password-reset';
import { Utilisateur } from './utilisateur';
import { UtilisateurForm } from '../shared/utilisateur-form.model';

const URI = {
  USER: {
    BASE: (baseUri) => `${baseUri}/utilisateur`,
    USER: (baseUri, userId) => `${baseUri}/utilisateur/${userId}`,
    UPDATE_USER: (baseUri, userId) => `${baseUri}/private/utilisateur/${userId}`,
    ACTIVATE_USER: (baseUri, userId) => `${baseUri}/utilisateur/activer/${userId}`,
    RESET_PASSWORD: {
      BASE: (baseUri) => `${baseUri}/utilisateur/mot_de_passe/reinitialisation`,
      REQUEST: (baseUri) => `${baseUri}/utilisateur/mot_de_passe/reinitialisation/demande`,
      CHECK: (baseUri, passwordResetKey) => `${baseUri}/utilisateur/mot_de_passe/reinitialisation/verification/${passwordResetKey}`,
    },
    SIGN_UP: (baseUri) => `${baseUri}/utilisateur/inscription`,
    PASSWORD: (baseUri) => `${baseUri}/utilisateur/mot_de_passe`,
    LOGOUT: (baseUri) => `${baseUri}/private/utilisateur/logout`,
  },
};

@Injectable()
export class UtilisateurService {
  public userEvents$ = new BehaviorSubject<Utilisateur>(null);

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private localStorageService: LocalStorageStarService,
    @Inject(BASE_URI) private baseUri: string
  ) {}

  authentifie(nomUtilisateur: string, motDePasse: string): Observable<Utilisateur> {
    return this.authService.login(nomUtilisateur, motDePasse).pipe(
      switchMap(() => {
        return this.getConnectedUtilisateur().pipe(tap((utilisateur) => this.localStorageService.setLocale(utilisateur.langue)));
      })
    );
  }

  deconnexion(): Observable<any> {
    this.userEvents$.next(null);
    return this.logout().pipe(map(() => this.authService.logout()));
  }

  /**
   * Renvoie un observable qui contiendra true ou false (si un utilisateur est connecté ou pas)
   * @returns {Observable<boolean>}
   */
  estConnecte(): Observable<boolean> {
    return this.getConnectedUtilisateur().pipe(
      map((utilisateur) => !!utilisateur),
      catchError(() => of(false))
    );
  }

  getConnectedUtilisateur(): Observable<Utilisateur> {
    return this.authService.getAuthDetails().pipe(
      map((authenticationDetails) => {
        const utilisateur = new Utilisateur().fromData(authenticationDetails.utilisateur);
        this.userEvents$.next(utilisateur);
        return utilisateur;
      }),
      catchError(() => of(null))
    );
  }

  hasAccess(authorizedProfils: Profil[]) {
    const user = this.userEvents$.getValue();
    return user && !!authorizedProfils.find((profil) => profil === user.habilitations.profil);
  }

  hasDelegation(delegation: Delegation) {
    const utilisateur = this.userEvents$.getValue();

    if (!utilisateur) {
      return false;
    }

    if (delegation === Delegation.DELEGATION_OFFRE_STOCKAGE) {
      return utilisateur.habilitations.hasDelegationOffreStockage;
    }

    if (delegation === Delegation.DELEGATION_OFFRE_TRANSPORT) {
      return utilisateur.habilitations.hasDelegationOffreTransport;
    }

    return false;
  }

  refreshConnectedUtilisateur(): Observable<Utilisateur> {
    return this.authService.refresh().pipe(pluck('utilisateur'));
  }

  creerUtilisateur(utilisateur: NouvelUtilisateur): Observable<Utilisateur> {
    return this.http.post<Utilisateur>(URI.USER.BASE(this.baseUri), utilisateur);
  }

  creerUtilisateurParAdmin(utilisateur: NouvelUtilisateur): Observable<Utilisateur> {
    return this.http.post<Utilisateur>(URI.USER.BASE(this.baseUri), utilisateur);
  }

  getUtilisateurs(): Observable<Utilisateur[]> {
    return this.http.get<Utilisateur[]>(URI.USER.BASE(this.baseUri));
  }

  getUtilisateur(utilisateurId: string): Observable<Utilisateur> {
    return this.http.get<Utilisateur>(URI.USER.USER(this.baseUri, utilisateurId));
  }

  getUtilisateurPourActivation(utilisateurId: string): Observable<Utilisateur> {
    return this.http.get<Utilisateur>(URI.USER.ACTIVATE_USER(this.baseUri, utilisateurId));
  }

  updateUtilisateur(utilisateur: Utilisateur) {
    return this.http.put(URI.USER.UPDATE_USER(this.baseUri, utilisateur.id), utilisateur);
  }

  updateUtilisateurLang(utilisateur: Utilisateur, lang: string): Observable<any> {
    return this.http.patch(URI.USER.UPDATE_USER(this.baseUri, utilisateur.id), { langue: lang });
  }

  updateUtilisateurPourActivation(utilisateur: Utilisateur) {
    return this.http.put(URI.USER.ACTIVATE_USER(this.baseUri, utilisateur.id), utilisateur);
  }

  askPasswordReset(resetPasswordEmail: AskPasswordReset) {
    return this.http.post(URI.USER.RESET_PASSWORD.REQUEST(this.baseUri), resetPasswordEmail);
  }

  validatePasswordResetKey(passwordResetKey: string) {
    return this.http.get(URI.USER.RESET_PASSWORD.CHECK(this.baseUri, passwordResetKey));
  }

  reinitUtilisateurMotDePasse(passwordReset: SendPasswordReset) {
    return this.http.post(URI.USER.RESET_PASSWORD.BASE(this.baseUri), passwordReset);
  }

  sendUtilisateurDemandeInscription(user: UtilisateurForm) {
    return this.http.post(URI.USER.SIGN_UP(this.baseUri), user);
  }

  updateUtilisateurMotDePasse(passwordChange: ChangeMotDePasseParameters) {
    return this.http.put(URI.USER.PASSWORD(this.baseUri), passwordChange);
  }

  logout(): Observable<any> {
    return this.http.get(URI.USER.LOGOUT(this.baseUri));
  }
}
