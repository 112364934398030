<star-dialog dialogTitle="COMMONS.CONFIRMATION" backgroundIcon="done_all">
  <div class="message" dialogContent>{{message | translate}}</div>
  <button
    mat-raised-button
    #action
    dialogAction
    color="secondary"
    [mat-dialog-close]="false">{{ cancelLabel | translate }}</button>
  <button
    mat-raised-button
    #action
    dialogAction
    color="primary"
    [mat-dialog-close]="true">{{ confirmLabel | translate }}</button>
</star-dialog>
