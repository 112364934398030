import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CMSComponent, CMSComponentData } from '../../../models/cms.model';

const YOUTUBE_BASE_URL = 'https://www.youtube.com/embed/';

@Component({
  selector: 'cms-slice-video',
  templateUrl: './cms-slice-video.component.html',
  styleUrls: ['./cms-slice-video.component.scss'],
})
export class CMSSliceVideoComponent implements CMSComponent, OnInit {
  @Input('componentData') readonly componentData: CMSVideo;
  public safeUrl: SafeResourceUrl;
  public playingVideo = false;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(YOUTUBE_BASE_URL + this.componentData.fields.youtubeId + '?autoplay=1');
  }

  playVideo(): void {
    this.playingVideo = true;
  }
}

export interface CMSVideo extends CMSComponentData {
  fields: {
    suptitle: string;
    title: string;
    cover: {
      fields: {
        file: {
          contentType: string;
          details: {
            image: {
              width: number;
              height: number;
            };
            size: number;
          };
          fileName: string;
          url: string;
        };
        title: string;
      };
    };
    youtubeId: string;
  };
}
