import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EntityFilterStatusStore {
  private readonly _statusSource = new BehaviorSubject<boolean>(false);

  readonly status$ = this._statusSource.asObservable();

  isEnabled(): boolean {
    return this._statusSource.getValue();
  }

  enable(): void {
    this._statusSource.next(true);
  }

  disable(): void {
    this._statusSource.next(false);
  }
}
