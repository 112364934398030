export enum UtilisateurStatut {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/consistent-type-assertions
  EN_ATTENTE_ACTIVATION_CLIENT = <any>'EN_ATTENTE_ACTIVATION_CLIENT',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/consistent-type-assertions
  DESACTIVE = <any>'DESACTIVE',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/consistent-type-assertions
  BLOQUE = <any>'BLOQUE',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/consistent-type-assertions
  ACTIF = <any>'ACTIF',
}
