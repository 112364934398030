import { Inject, Injectable } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class Config {
  public loaded = false;

  constructor(@Inject(APP_BASE_HREF) private baseHref: string) {}

  getBaseHref(): string {
    return this.baseHref;
  }
}
