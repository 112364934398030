import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[checkPassword][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CheckPasswordDirective, multi: true }],
})
export class CheckPasswordDirective implements Validator {
  @Input() checkPassword: string;

  validate(control: AbstractControl): { [key: string]: any } {
    return this.validatePassword(control) ? null : { notSame: true };
  }

  private validatePassword(control: AbstractControl) {
    return control.value ? control.value === this.checkPassword : true;
  }
}
