import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Profil } from '@terega-portal/auth-lib';
import { MenuService } from './menu.service';

@Directive({
  selector: '[starIsAllowed]',
})
export class IsAllowedDirective {
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private menuService: MenuService) {}

  @Input() set starIsAllowed(profils: Profil[]) {
    if (!profils) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.menuService.getConnectedUser().subscribe((user) => {
        if (user && user.hasRole(profils)) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
    }
  }
}
