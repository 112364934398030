import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ApiGatewayAuthUtils } from './api-gateway-auth.utils';
import { AuthService } from '../auth.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ApiGatewayAuthInterceptor implements HttpInterceptor {
  private DEBUG = 0;
  private IE = false;

  constructor(private auth: AuthService, private apiGatewayAuth: ApiGatewayAuthUtils) {
    this.IE = this.isInternetExplorer();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAuthRequest = (): boolean => req.url.indexOf('authentication') >= 0;
    const isSsoRequest = (): boolean => req.url.indexOf('sso_token') >= 0;
    const isTetraRightRequest = (): boolean => req.url.indexOf('tetra_right') >= 0;

    const endpoint = /(^https?:\/\/[^\/]+)/g.exec(req.url);
    if (!endpoint || (isAuthRequest() && !isSsoRequest() && !isTetraRightRequest())) {
      return next.handle(req);
    }

    if (this.DEBUG) {
      console.log('[HTTP Interceptor] will check current authDetails : ' + req.url);
    }

    // Get the auth header from the service.
    return this.auth.getAuthDetails().pipe(
      switchMap((authDetails) => {
        // If a user is authenticated and the request is an external one (got a domain with http://)
        if (this.DEBUG) {
          console.log('[HTTP Interceptor] will add Authorization tokens to req : ' + req.url);
        }
        const newHeaders = this.apiGatewayAuth.getHeadersForApiGAuth(req, authDetails);
        // add 'Star-Authorization' for '/private' routes
        // if (authDetails && authDetails.authResult && req.url.indexOf('/private/') > -1) {
        if (authDetails && authDetails.authResult) {
          newHeaders['star-authorization'] = authDetails.authResult.IdToken;
        }
        req = req.clone({ setHeaders: newHeaders });

        // Désactivation du cache IE11
        if (req.method === 'GET' && this.IE) {
          req = req.clone({
            headers: req.headers
              .set('Cache-Control', 'no-cache')
              .set('Pragma', 'no-cache')
              .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
              .set('If-Modified-Since', '0'),
          });
        }

        // Pass on the cloned request instead of the original request.
        return next.handle(req);
      })
    );
  }

  private isInternetExplorer() {
    let IE = false;
    const ua = navigator.userAgent;
    if (navigator.appName === 'Microsoft Internet Explorer') {
      const re = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');
      if (re.exec(ua) != null) {
        IE = true;
      }
    } else if (navigator.appName === 'Netscape') {
      const re = new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})');
      if (re.exec(ua) != null) {
        IE = true;
      }
    }
    if (IE) {
      console.log('IE detected.');
    }
    return IE;
  }
}
