import { Component, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { CMSContentLoaderDirective } from '../../directives/cms-content-loader.directive';
import { CMSResolverService } from '../../services/cms-resolver.service';
import { CMSComponentData, CMSComponent } from '../../models/cms.model';

@Component({
  selector: 'cms-component-resolver',
  template: '<ng-template cms-content-loader></ng-template>',
})
export class CMSComponentResolver implements CMSComponent, OnChanges {
  @Input('componentData') public readonly componentData: CMSComponentData;
  @ViewChild(CMSContentLoaderDirective, { static: true }) cmsContentLoaderDirective: CMSContentLoaderDirective;

  constructor(private cmsService: CMSResolverService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.componentData) {
      this.resolveComponent();
    }
  }

  private resolveComponent(): void {
    if (this.componentData) {
      const componentName = this.componentData.sys.contentType.sys.id;
      const resolvedComponent = this.cmsService.resolveComponent(componentName);

      if (resolvedComponent) {
        const viewContainerRef = this.cmsContentLoaderDirective.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(resolvedComponent);
        (componentRef.instance as CMSComponent).componentData = this.componentData;
      }
    }
  }
}
