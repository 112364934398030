import { APP_BASE_HREF } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Profil } from '@terega-portal/auth-lib';
import { StarEventBroadcasterService, StarEventType } from '@terega-portal/shared-lib';
import { CustomIconService } from '../custom-icon/custom-icon.service';

@Component({
  selector: 'star-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() unreadEventsCount: number;

  public Profil = Profil;

  constructor(
    private starEventBroadcaster: StarEventBroadcasterService,
    private customIconService: CustomIconService,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {}

  ngOnInit() {
    this.customIconService.addIcon('notif', `${this.baseHref}/assets/common/icons/notif.svg`);
  }

  toggleMenu($event: Event) {
    this.starEventBroadcaster.send(StarEventType.MENU_CHANGED);
  }

  goToEvents() {
    window.location.href = '/star-app/events';
  }
}
