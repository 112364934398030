<form>
  <mat-form-field class="w-100 mat-form-field-small" appearance="outline">
    <mat-icon matPrefix class="mr-2">search</mat-icon>
    <mat-label>{{'UI_FILTRES.SEARCH' | translate}}</mat-label>
    <input type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto"
           [placeholder]="'UI_FILTRES.SEARCH_PAGE' | translate">

    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayItem">
      <ng-container *ngIf="searchControl.value?.length >= searchMinLength">
        <mat-option *ngFor="let translatedItem of filteredTranslateItems$ | async" [value]="translatedItem"
                    (onSelectionChange)="changePage(translatedItem.menuItem.value)">
          <span *ngFor="let item of translatedItem.menuItem.breadcrumb; let last = last"
                [class.page-name]="last">{{'UI_MENU.' + item | translate}}{{ last ? '' : ' > ' }}</span>
          <mat-icon *ngIf="translatedItem.menuItem.value.tetraLink" class="tetra-link-icon" svgIcon="menu-external-link"></mat-icon>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</form>
