<star-menu (onMenuStateChange)="onMenuStateChange($event)" [outsideClick]="clickOnWrapper.asObservable()">
  <mat-icon class="pointer" matTooltip="Publication N° : {{ appVersion }}" matTooltipPosition="right">info</mat-icon>
</star-menu>
  <star-header [class.bring-forward]="searching"
               [unreadEventsCount]="unreadEventsCount$ | async">
    <app-menu-search-bar left (click)="searching = true" (focusout)="searching = false" ></app-menu-search-bar>
    <app-entity-filter class="mr-4"
                       *ngIf="(entityFilterStatusStore.status$ | async) && hasEntityFilterAccess"></app-entity-filter>
  </star-header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <star-consent-bar></star-consent-bar>
  <star-footer></star-footer>
<div *ngIf="isMenuOpen || searching" [@darkenBackground] class="overlay" (click)="clickOnWrapper.next()">
</div>
