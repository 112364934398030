import { EventType } from '../enums/event-type.enum';

export class EventHelper {
  /**
   * une liste d'events<
   */
  public static getEventi18nKey(eventType: EventType): string {
    return eventType ? 'EVENT_TYPE.' + String(eventType).toUpperCase() : '';
  }
}
