import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { APP_BASE_HREF } from '@angular/common';

export function HttpLoaderFactory(http: HttpClient, baseHref: string) {
  return new TranslateHttpLoader(http, `${baseHref}/assets/i18n/`, '.json?d=' + Date.now());
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return `Erreur traduction [${params.key}]`;
  }
}

export const childTranslate: any = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient, APP_BASE_HREF],
  },
  missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
  useDefaultLang: false,
};
