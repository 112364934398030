import { TranslateService } from '@ngx-translate/core';
import { StarEventBroadcasterService } from '../event-broadcaster/star-event-broadcaster.service';
import { LocalStorageStarService } from '../localstorage/localstorage.service';
import { StarEventType } from '../event-broadcaster/star-event-type';
import { Title } from '@angular/platform-browser';
import { firstValueFrom, merge, Observable } from 'rxjs';
import { Injector } from '@angular/core';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  translate.addLangs(['en', 'fr']);

  const starEventBroadcaster = injector.get(StarEventBroadcasterService);

  const localeChanged$ = starEventBroadcaster.listen(StarEventType.LOCALE_CHANGED);
  const userChanged$ = starEventBroadcaster.listen(StarEventType.USER_CHANGED);
  merge(localeChanged$, userChanged$).subscribe(() => refreshCurrentLanguageFromLocalStorage(translate, injector));

  return () => {
    return firstValueFrom(refreshCurrentLanguageFromLocalStorage(translate, injector))
      .catch((err) => console.error('Problem with language initialization.'))
      .then((respones) => null);
  };
}

function refreshCurrentLanguageFromLocalStorage(translate: TranslateService, injector: Injector): Observable<any> {
  const localStorage = injector.get(LocalStorageStarService);

  let selectedLanguage = localStorage.getLocale();

  if (selectedLanguage === '') {
    selectedLanguage = translate.getBrowserLang();
  }

  if (selectedLanguage !== 'fr' && selectedLanguage !== 'en') {
    selectedLanguage = 'en';
  }

  translate.setDefaultLang(selectedLanguage);

  const titleService = injector.get(Title);
  translate.get('TITLE').subscribe((message: string) => titleService.setTitle(message));

  return translate.use(selectedLanguage);
}
