import { Injectable } from '@angular/core';
import { ResolveData, Route, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RouterResolverBuilder {
  private resolvers: ResolveData = {};

  public addResolvers(resolvers: ResolveData): RouterResolverBuilder {
    this.resolvers = { ...this.resolvers, ...resolvers };
    return this;
  }

  public applyTo(router: Router): void {
    const routes: Route[] = router.config.map((route) => ({
      ...route,
      resolve: { ...route.resolve, ...this.resolvers },
    }));

    router.resetConfig(routes);
  }
}
