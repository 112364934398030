import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CMSResolverService } from '../../services/cms-resolver.service';
import { CMSComponent, CMSComponentData } from '../../models/cms.model';

@Component({
  selector: 'cms-content-loader',
  template: '<cms-component-resolver *ngIf="!!componentData" [componentData]="componentData"></cms-component-resolver>',
})
export class CMSContentLoader implements CMSComponent, OnChanges {
  @Input('contentId') private readonly contentId: string;
  @Input() private readonly lang: string;
  public componentData: CMSComponentData;

  constructor(private cmsService: CMSResolverService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.initComponentData();
  }

  private initComponentData(): void {
    this.cmsService
      .retrieveContent(this.contentId)
      .then((cmsComponentData: CMSComponentData) => (this.componentData = cmsComponentData))
      .catch((error) => console.error(`Error during retrieveContent: ${error}`));
  }
}
