import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UtilisateurService } from '../user/utilisateur.service';
import { StarRouterService } from '@terega-portal/shared-lib';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class AccessGuard implements CanActivate {
  constructor(private routerService: StarRouterService, private utilisateurService: UtilisateurService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.utilisateurService.estConnecte().pipe(
      switchMap((utilisateurConnecte) => {
        if (!utilisateurConnecte) {
          return of(null);
        }
        return this.utilisateurService.getConnectedUtilisateur();
      }),
      map((utilisateur) => {
        if (!utilisateur) {
          this.routerService.navigate(['/star-app/connexion'], { queryParams: { redirectUrl: `/app${state.url}` } });
          return false;
        }
        return this.utilisateurService.hasAccess(route.data.authorizedProfils);
      })
    );
  }
}
