import { Component, Input } from '@angular/core';
import { CMSComponentData, CMSComponent } from '../../../models/cms.model';
import { CMSFrontImg } from '../../cms-misc-components/misc-front-img/cms-misc-front-img.component';
import { CMSCTA } from '../../cms-misc-components/misc-cta/cms-misc-cta.component';
import { CMSDocument } from '../../cms-misc-components/misc-document/cms-misc-document.component';

@Component({
  selector: 'cms-slice-imagetext',
  templateUrl: './cms-slice-imagetext.component.html',
  styleUrls: ['./cms-slice-imagetext.component.scss'],
})
export class CMSSliceImageTextComponent implements CMSComponent {
  @Input('componentData') componentData: CMSImageText;
}

export interface CMSImageText extends CMSComponentData {
  fields: {
    content: CMSDocument; // Wrap in an array before passing to <cms-misc-document>
    cta: CMSCTA;
    image: CMSFrontImg;
    reversed: boolean; // Normal (false) layout is txt-img, Reversed (true) is img-txt
    title: string;
    theme: string;
  };
}
