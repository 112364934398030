<ng-container *ngFor="let node of componentData">
  <ng-container [ngSwitch]="node?.nodeType">

    <ng-container *ngSwitchCase="'document'">
      <div><cms-misc-document [componentData]="node?.content"></cms-misc-document></div>
    </ng-container>

    <ng-container *ngSwitchCase="'paragraph'">
      <p><cms-misc-document [componentData]="node?.content"></cms-misc-document></p>
    </ng-container>

    <ng-container *ngSwitchCase="'text'">
      {{node?.value}}
    </ng-container>

    <ng-container *ngSwitchCase="'asset-hyperlink'">
      <cms-asset-hyperlink [assetId]="node?.data.target.sys.id">
        <cms-misc-document [componentData]="node?.content"></cms-misc-document>
      </cms-asset-hyperlink>
    </ng-container>

    <ng-container *ngSwitchCase="'entry-hyperlink'">
      <a [href]="toAbsoluteUrl(node?.data.target.fields.slug)" target="_blank" class="hyperlink">
        <cms-misc-document [componentData]="node?.content"></cms-misc-document>
      </a>
    </ng-container>

    <ng-container *ngSwitchCase="'hyperlink'">
      <a [href]="toAbsoluteUrl(node?.data.uri)" target="_blank" class="hyperlink">
        <cms-misc-document [componentData]="node?.content"></cms-misc-document>
      </a>
    </ng-container>

    <ng-container *ngSwitchCase="'unordered-list'">
      <ul><cms-misc-document [componentData]="node?.content"></cms-misc-document></ul>
    </ng-container>

    <ng-container *ngSwitchCase="'ordered-list'">
      <ol><cms-misc-document [componentData]="node?.content"></cms-misc-document></ol>
    </ng-container>

    <ng-container *ngSwitchCase="'list-item'">
      <li><cms-misc-document [componentData]="node?.content[0].content"></cms-misc-document></li>
    </ng-container>

    <ng-container *ngSwitchCase="'embedded-entry-block'">
      <div class="cms-misc-img-container">
        <cms-misc-front-img [componentData]="node?.data?.target"></cms-misc-front-img>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault><!-- {{node?.nodeType}} --></ng-container>

  </ng-container>
</ng-container>

