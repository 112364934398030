import { Inject, Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class StarEventBroadcasterService {
  constructor(@Inject(DOCUMENT) private document: any) {}

  public listen(eventType: string): Observable<any> {
    return fromEvent(this.document, eventType);
  }

  public send(eventType: string): void {
    const evt = this.document.createEvent('HTMLEvents');
    evt.initEvent(eventType, false, true);
    this.document.dispatchEvent(evt);
  }
}
