import { AbstractEventDetails } from './abstract-event-details.model';

/**
 * event details for type END_SUBSCRIPTION_PIC
 */
export class EndSubscriptionEventDetails extends AbstractEventDetails {
  constructor() {
    super();
  }
}
