import { AbstractEventDetails } from './abstract-event-details.model';

/**
 * event details for type ACTION_MARKET_IMBALANCE
 */
export class ActionMarketImbalanceEventDetails extends AbstractEventDetails {
  constructor() {
    super();
  }
}
