<div class="cms-slice__video mb-5">
  <div class="mx-auto"
       [ngStyle]="{'width.px': componentData.fields.cover.fields.file.details.image.width}">
    <div *ngIf="!playingVideo; else youtubeVideo" class="cms-slice__video-placeholder">
      <img [src]="componentData.fields.cover.fields.file.url"
           [width]="componentData.fields.cover.fields.file.details.image.width"
           [height]="componentData.fields.cover.fields.file.details.image.height">
      <div class="cms-slice__video-launcher" (click)="playVideo()">
        <mat-icon>play_arrow</mat-icon>
        <div class="d-flex flex-column">
          <h2>{{ componentData.fields.title }}</h2>
          <span>{{ componentData.fields.suptitle }}</span>
        </div>
      </div>
    </div>

    <ng-template #youtubeVideo>
      <iframe [width]="componentData.fields.cover.fields.file.details.image.width"
              [height]="componentData.fields.cover.fields.file.details.image.height"
              [src]="safeUrl" frameborder="0" allowfullscreen></iframe>
    </ng-template>
  </div>
</div>

