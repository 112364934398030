import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'star-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() optionList;
  @Input() selectedValue;
  @Output() onSelectionChange = new EventEmitter();
  @Input() multiple = false;
  @Input() placeHolder: string;

  ngOnInit(): void {
    this.onSelect();
  }

  onSelect(): void {
    this.onSelectionChange.emit(this.selectedValue);
  }
}
