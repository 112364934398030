<section class="notification notification--{{status}}">
  <mat-icon>{{icon}}</mat-icon>
  <div class="notification__header">
    <h1>{{title | translate}}</h1>
    <p *ngIf="description">{{description | translate}}</p>
  </div>
  <div *ngIf="date" class="notification__date">
    <ng-content select="[notificationDate]"></ng-content>
  </div>
  <div *ngIf="actions.length" class="notification__actions">
    <ng-content select="[notificationAction]"></ng-content>
  </div>
  <div *ngIf="content" class="notification__content">
    <hr/>
    <ng-content select="[notificationContent]"></ng-content>
  </div>
</section>
