import { TypeEntite } from './type-entite.enum';

export class Entite {
  id: string;
  codeAti: number;
  dateMaj: Date;
  nom: string;
  typeEntite: TypeEntite;
  eicCode: string;
  billingReferents?: string[];
}
