import { EMPTY, Observable, throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { UtilisateurService } from '../../../user/utilisateur.service';
import { MessageService, StarRouterService } from '@terega-portal/shared-lib';
import { TranslateService } from '@ngx-translate/core';
import { UtilisateurStatut } from '../../../shared/utilisateur-statut.enum';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  HTTP_CODE_UNAUTHORISED = 403;

  constructor(private injector: Injector, private translateService: TranslateService, private messageService: MessageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request.clone()).pipe(
      catchError((error) => {
        if (error.status === this.HTTP_CODE_UNAUTHORISED) {
          this.handleAuthError(error.error);
          return EMPTY;
        }

        return throwError(error);
      })
    );
  }

  private handleAuthError(authError: UtilisateurStatut): Promise<boolean> {
    this.injector.get(UtilisateurService).deconnexion();
    const routerService = this.injector.get(StarRouterService);

    if (authError === UtilisateurStatut.BLOQUE || authError === UtilisateurStatut.DESACTIVE) {
      this.handleAuthLocked(authError);
      return Promise.resolve(true);
    } else {
      return routerService.navigate(['/star-app/connexion']);
    }
  }

  private handleAuthLocked(authError: UtilisateurStatut) {
    if (UtilisateurStatut.BLOQUE === authError) {
      this.translateService.get('UTILISATEUR.ERREURS.COMPTE_BLOQUE').subscribe((messageTranslated) => {
        this.messageService.error(messageTranslated).subscribe();
      });
    } else if (UtilisateurStatut.DESACTIVE === authError) {
      this.translateService.get('UTILISATEUR.ERREURS.COMPTE_DESACTIVE').subscribe((messageTranslated) => {
        this.messageService.error(messageTranslated).subscribe();
      });
    }
  }
}
