import { Menu } from '../menu.model';

export const STORAGE_MENU: Menu = {
  name: 'STORAGE',
  icon: 'storage',
  items: [
    {
      name: 'OFFER',
      items: [
        {
          name: 'CONTRACTUAL_INFORMATION_STORAGE',
          link: '/edito/tarifs-contrats-calendrier-dencheres-de-terega-les-etapes-client-cles-du-stockage-de-gaz',
        },
        {
          name: 'STORAGE_COMMERCIAL_OFFER_BOOKLET',
          resourceLink: { en: '/resources/offers/offre_stockage_en.pdf', fr: '/resources/offers/offre_stockage_fr.pdf' },
        },
        { name: 'STORAGE_PRODUCTS', link: '/edito/capacites-de-stockage-les-produits-proposes-par-terega' },
        { name: 'STOREPLACE_AUCTIONS', link: '/edito/storeplace-la-place-des-encheres-de-stockage-de-gaz-de-terega' },
        {
          name: 'SECONDARY_MARKET',
          link: '/edito/terega-fait-rimer-services-et-flexibilite-en-matiere-de-stockage-de-gaz',
        },
        {
          name: 'ADDITIONAL_SERVICES_OF_STORAGE_OFFER',
          link: '/edito/capacites-de-stockage-decouvrez-les-services-additionnels-de-terega',
        },
      ],
    },
    {
      name: 'OPERATIONAL_DATA',
      items: [
        { name: 'STOCK', link: '/logistique/stockage' },
        { name: 'STORAGE_MAINTENANCES_REDUCTIONS', externalLink: '/star-app/logistique/maintenances-et-reductions/storage' },
      ],
    },
    {
      name: 'DOCUMENTATIONS_TOOLS',
      items: [{ name: 'STORAGE_TUTORIALS', link: '/edito/les-outils-de-terega-pour-votre-gestion-operationnelle' }],
    },
    {
      name: 'REGULATORY_INFORMATION',
      items: [
        {
          name: 'CAPACITY_MARKETING',
          link: '/edito/capacites-de-stockage-de-gaz-decouvrez-le-calendrier-de-commercialisation-de-terega',
        },
        {
          name: 'TRANSPARENCY_TEMPLATE_GSE',
          link: '/edito/stockage-de-gaz-comment-terega-sapproprie-les-regles-de-transparence',
        },
      ],
    },
  ],
};
