import { Component, Input, OnChanges } from '@angular/core';
import { Asset } from 'contentful';
import { Observable } from 'rxjs';
import { CMSResolverService } from '../../../../services/cms-resolver.service';

@Component({
  selector: 'cms-asset-hyperlink',
  templateUrl: './cms-asset-hyperlink.component.html',
})
export class CmsAssetHyperlinkComponent implements OnChanges {
  @Input() assetId: string;
  asset$: Observable<Asset>;

  constructor(private cmsService: CMSResolverService) {}

  ngOnChanges() {
    this.asset$ = this.cmsService.getAssetById(this.assetId);
  }
}
