import { ModuleWithProviders, NgModule } from '@angular/core';
import { StarSharedModule } from '@terega-portal/shared-lib';
import { AuthModuleConfig, AWS_AUTH_DETAILS, BASE_URI } from './auth.type';
import { ApiGatewayAuthUtils } from './auth/services/api-gateway/api-gateway-auth.utils';
import { AuthCachingService } from './auth/services/auth-caching.service';
import { AuthService } from './auth/services/auth.service';
import { AuthProvider } from './auth/services/providers/auth.provider';
import { AccessAuthorizedDirective } from './directive/access-authorized.directive';
import { AccessGuard } from './guards/access.guard';
import { AuthenticationGuard } from './guards/authentication.guard';
import { UtilisateurService } from './user/utilisateur.service';
import { FeatureFlippingGuard } from './guards/feature-flipping.guard';

@NgModule({
  imports: [StarSharedModule],
  exports: [AccessAuthorizedDirective],
  providers: [
    AuthenticationGuard,
    AccessGuard,
    FeatureFlippingGuard,
    AuthService,
    UtilisateurService,
    AuthProvider,
    ApiGatewayAuthUtils,
    AuthCachingService,
  ],
  declarations: [AccessAuthorizedDirective],
})
export class AuthModule {
  public static forRoot(config: AuthModuleConfig): ModuleWithProviders<StarSharedModule> {
    return {
      ngModule: StarSharedModule,
      providers: [
        { provide: AWS_AUTH_DETAILS, useValue: config.authDetails },
        { provide: BASE_URI, useValue: config.baseUri },
      ],
    };
  }
}
