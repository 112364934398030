<star-page [pageName]="componentData?.fields.seoTitle" [isCMS]="true" class="cms-page">
  <mat-card>
    <mat-card-header class="flex-wrap p-5 h-auto">
      <mat-card-title>{{ componentData?.fields.title }}</mat-card-title>
      <cms-misc-document [componentData]="[componentData?.fields.chapo]"></cms-misc-document>
    </mat-card-header>
    <mat-card-content class="p-6">
      <cms-component-resolver *ngFor="let slice of componentData?.fields.slices"
                              [componentData]="slice"></cms-component-resolver>
    </mat-card-content>
  </mat-card>
</star-page>
