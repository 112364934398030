import { Injectable } from '@angular/core';
import { Entite } from '@terega-portal/shared-lib';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EntityStore {
  private readonly _entitySource = new BehaviorSubject<Entite>(null);

  readonly entity$ = this._entitySource.asObservable();

  getEntity(): Entite {
    return this._entitySource.getValue();
  }

  setEntity(entite: Entite): void {
    this._entitySource.next(entite);
  }

  clear(): void {
    this._entitySource.next(null);
  }
}
