import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Utilisateur, UtilisateurService } from '@terega-portal/auth-lib';
import { LocalStorageStarService, StarEventBroadcasterService, StarEventType } from '@terega-portal/shared-lib';
import { pluck, startWith } from 'rxjs/operators';

@Component({
  selector: 'star-header-lang',
  templateUrl: './header-lang.component.html',
  styleUrls: ['./header-lang.component.scss'],
})
export class HeaderLangComponent implements OnInit, OnDestroy {
  language: string;
  private onLangChangeSubscription;

  constructor(
    public translate: TranslateService,
    private storage: LocalStorageStarService,
    private starEventBroadcaster: StarEventBroadcasterService,
    public utilisateurService: UtilisateurService
  ) {}

  ngOnInit() {
    this.utilisateurService.getConnectedUtilisateur().subscribe((user: Utilisateur) => {
      if (user) {
        this.language = user.langue;
      }
    });
    this.onLangChangeSubscription = this.translate.onLangChange
      .pipe(pluck('lang'), startWith(this.translate.currentLang))
      .subscribe((lang: string) => {
        this.language = lang;
      });
  }

  ngOnDestroy(): void {
    this.onLangChangeSubscription.unsubscribe();
  }

  switchLang() {
    const lang = this.translate.currentLang === 'en' ? 'fr' : 'en';
    this.language = lang;
    this.translate.use(lang);
    this.storage.setLocale(lang);
    this.starEventBroadcaster.send(StarEventType.LOCALE_CHANGED);
    this.utilisateurService.getConnectedUtilisateur().subscribe((user: Utilisateur) => {
      if (user) {
        this.utilisateurService.updateUtilisateurLang(user, lang).subscribe();
      }
    });
  }
}
