<header>
  <mat-toolbar color="primary" id="portail-header">
    <div class="logo-container">
      <mat-icon [svgIcon]="'terega-title'" class="title"></mat-icon>
    </div>
    <div class="mat-toolbar-left">
      <ng-content select="[left]"></ng-content>
    </div>
    <div class="mat-toolbar-right">
      <ng-content></ng-content>
      <ng-container>
        <div class="header-separator"></div>
        <div class="event-container">
          <a (click)="goToEvents()">
            <mat-icon [svgIcon]="'notif'"></mat-icon>
            <span *ngIf="unreadEventsCount > 0" class="events-counter">{{unreadEventsCount}}</span>
          </a>
        </div>
      </ng-container>
      <div class="header-separator"></div>
      <star-header-hour></star-header-hour>
      <div class="header-separator"></div>
      <star-header-user ignoreNode="STAR-HEADER-USER"></star-header-user>
      <div class="header-separator"></div>
      <star-header-lang></star-header-lang>
    </div>
  </mat-toolbar>
</header>
