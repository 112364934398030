import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

@Injectable()
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  showSuccess(message: string, duration = 5000): void {
    this.openSnackBar(message, 'check_circle', 'snack-bar-success', duration);
  }

  showError(message: string, duration = 5000): void {
    this.openSnackBar(message, 'cancel', 'snack-bar-error', duration);
  }

  private openSnackBar(message: string, icon: string, panelClass: string, duration: number): void {
    Promise.resolve().then(() => {
      this.snackBar.openFromComponent(SnackBarComponent, {
        data: {
          message: message,
          icon: icon,
        },
        duration: duration,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: panelClass,
      });
    });
  }
}
