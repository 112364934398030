import { AbstractEventDetails } from './abstract-event-details.model';
import { MaintenanceTypeEnum } from '../enums/maintenance-type.enum';
import { MaintenanceNatureEnum } from '../enums/maintenance-nature.enum';

export class ScheduledMaintenanceEventDetails extends AbstractEventDetails {
  public labels: {
    fr: string;
    en: string;
  };
  public nature: MaintenanceNatureEnum;
  public type: MaintenanceTypeEnum;
}
