import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Utilisateur } from './utilisateur';
import { UtilisateurService } from './utilisateur.service';

@Injectable({ providedIn: 'root' })
export class AuthenticatedUserResolver implements Resolve<Utilisateur> {
  constructor(private utilisateurService: UtilisateurService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Utilisateur> {
    return this.utilisateurService.getConnectedUtilisateur();
  }
}
