import { Component, ContentChild, ContentChildren, ElementRef, Input, QueryList } from '@angular/core';

@Component({
  selector: 'star-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() status = 'disabled';

  @ContentChildren('action') actions: QueryList<ElementRef>;
  @ContentChild('date') date: ElementRef;
  @ContentChild('content') content: ElementRef;
}
