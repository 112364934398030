import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterTestingModule } from '@angular/router/testing';
import { HttpClientTestingModule } from '@angular/common/http/testing';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { testTranslate } from './test.translate';
import { MessageService } from '../message/message.service';
import { LocalStorageStarService } from '../localstorage/localstorage.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

// const mockLocalStorageService = { getLocale: () => 'FR' };

@NgModule({
  imports: [
    RouterTestingModule,
    TranslateModule.forRoot(testTranslate),
    HttpClientTestingModule,
    NoopAnimationsModule,
    MatDialogModule,
    MatSnackBarModule,
  ],
  exports: [RouterTestingModule, TranslateModule, HttpClientTestingModule, MatDialogModule, MatSnackBarModule],
  providers: [TranslateService, { provide: LocalStorageStarService, useValue: 'FR' }, MessageService],
})
export class StarTestModule {}
