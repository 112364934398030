import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tetraLink',
})
export class TetraLinkPipe implements PipeTransform {
  transform(url: string, locale?: string): string {
    return `/star-app/redirectTetraSso?tetraUri=${this.addLangToUrl(url, locale)}`;
  }

  private addLangToUrl(url: string, locale?: string) {
    return encodeURIComponent(`${url}${(locale || '') && '&lang=' + locale}`);
  }
}
