import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'star-consent-bar',
  templateUrl: './consent-bar.component.html',
  styleUrls: ['./consent-bar.component.scss'],
})
export class ConsentBarComponent implements OnInit {
  showConsent = true;

  ngOnInit(): void {
    const sessionShowConsent = JSON.parse(sessionStorage.getItem('showConsent'));
    if (typeof sessionShowConsent === 'boolean') {
      this.showConsent = sessionShowConsent;
    }
    const consentValue = this.getCookie('consent');
    const gaValue = this.getCookie('GA_ENABLED');
    if (consentValue === '1') {
      this.showConsent = false;
    }
    if (consentValue === '1' && gaValue === '1') {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (window as any).initGA();
    }
  }

  closeConsent(): void {
    this.showConsent = false;
    sessionStorage.setItem('showConsent', JSON.stringify(this.showConsent));
  }

  acceptConset(): void {
    this.showConsent = false;
    sessionStorage.setItem('showConsent', JSON.stringify(this.showConsent));
    this.writeCookie('consent', '1', 396);
    this.writeCookie('GA_ENABLED', '1', 396);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (window as any).initGA();
  }

  writeCookie(key, value, days): void {
    const date = new Date();
    // Default at 365 days.
    days = days || 365;
    // Get unix milliseconds at current time plus number of days
    date.setTime(+date + days * 86400000); // 24 * 60 * 60 * 1000
    window.document.cookie = key + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
    return value;
  }

  getCookie(name): string | void {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
      return match[2];
    }
  }
}
