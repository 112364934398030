import { Injectable, Injector } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { AWS_AUTH_DETAILS } from '../../../auth.type';
import { AuthenticationDetails } from '../models/authentication-details.model';
import * as Url from 'url';
import { HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';

@Injectable()
export class ApiGatewayAuthUtils {
  static AWS_SHA_256 = 'AWS4-HMAC-SHA256';
  static AWS4_REQUEST = 'aws4_request';
  static AWS4 = 'AWS4';
  static X_AMZ_DATE = 'x-amz-date';
  static X_AMZ_SECURITY_TOKEN = 'x-amz-security-token';
  static HOST = 'host';
  static AUTHORIZATION = 'Authorization';

  apiConfig = {
    service: 'execute-api',
    defaultContentType: 'application/json',
    defaultAcceptType: 'application/json',
    systemClockOffset: 0,
  };

  private DEBUG = 0;

  constructor(private _injector: Injector) {}

  private hash = CryptoJS.SHA256;
  private hexEncode = CryptoJS.enc.Hex.stringify;
  private hmac = (secret, value) => CryptoJS.HmacSHA256(value, secret, { asBytes: true });

  /**
   * EncodeURIComponent
   * @param str
   * @returns {string}
   */
  protected fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  /**
   * Strings building for request signature
   */

  protected hashCanonicalRequest(request) {
    return this.hexEncode(this.hash(request));
  }

  protected buildCanonicalRequest(method, path, queryParams, headers, payload) {
    const canonicalRequest =
      method +
      '\n' +
      this.buildCanonicalUri(path) +
      '\n' +
      this.buildCanonicalQueryString(queryParams) +
      '\n' +
      this.buildCanonicalHeaders(headers) +
      '\n' +
      this.buildCanonicalSignedHeaders(headers) +
      '\n' +
      this.hexEncode(this.hash(payload));

    if (this.DEBUG) {
      console.log('[APIGatewayAuth] Signature - canonicalRequest :', canonicalRequest);
    }
    return canonicalRequest;
  }

  protected buildCanonicalUri(uri) {
    return encodeURI(uri);
  }

  protected buildCanonicalQueryString(queryParams) {
    if (Object.keys(queryParams).length < 1) {
      return '';
    }

    const sortedQueryParams = [];
    for (const property in queryParams) {
      if (queryParams.hasOwnProperty(property)) {
        sortedQueryParams.push(property);
      }
    }
    sortedQueryParams.sort();

    let canonicalQueryString = '';
    for (let i = 0; i < sortedQueryParams.length; i++) {
      canonicalQueryString += sortedQueryParams[i] + '=' + this.fixedEncodeURIComponent(queryParams[sortedQueryParams[i]]) + '&';
    }

    canonicalQueryString = canonicalQueryString.substr(0, canonicalQueryString.length - 1);

    if (this.DEBUG) {
      console.log('[APIGatewayAuth] Signature - canonicalQueryString :', canonicalQueryString);
    }

    return canonicalQueryString;
  }

  protected buildCanonicalHeaders(headers) {
    let canonicalHeaders = '';
    const sortedKeys = [];
    for (const property in headers) {
      if (headers.hasOwnProperty(property)) {
        sortedKeys.push(property);
      }
    }
    sortedKeys.sort();

    for (let i = 0; i < sortedKeys.length; i++) {
      canonicalHeaders += sortedKeys[i].toLowerCase() + ':' + headers[sortedKeys[i]] + '\n';
    }

    if (this.DEBUG) {
      console.log('[APIGatewayAuth] Signature - canonicalHeaders :', canonicalHeaders);
    }

    return canonicalHeaders;
  }

  protected buildCanonicalSignedHeaders(headers) {
    const sortedKeys = [];
    for (const property in headers) {
      if (headers.hasOwnProperty(property)) {
        sortedKeys.push(property.toLowerCase());
      }
    }
    sortedKeys.sort();

    const sortedKeyString = sortedKeys.join(';');

    if (this.DEBUG) {
      console.log('[APIGatewayAuth] Signature - sortedKeyString :', sortedKeyString);
    }

    return sortedKeyString;
  }

  protected buildStringToSign(datetime, credentialScope, hashedCanonicalRequest) {
    const stringToSign = ApiGatewayAuthUtils.AWS_SHA_256 + '\n' + datetime + '\n' + credentialScope + '\n' + hashedCanonicalRequest;

    if (this.DEBUG) {
      console.log('[APIGatewayAuth] Signature - stringToSign :', stringToSign);
    }

    return stringToSign;
  }

  protected buildCredentialScope(datetime, region, service) {
    const credentialScope = datetime.substr(0, 8) + '/' + region + '/' + service + '/' + ApiGatewayAuthUtils.AWS4_REQUEST;

    if (this.DEBUG) {
      console.log('[APIGatewayAuth] Signature - credentialScope :', credentialScope);
    }

    return credentialScope;
  }

  protected calculateSigningKey(secretKey, datetime, region, service) {
    const signinKey = this.hmac(
      this.hmac(this.hmac(this.hmac(ApiGatewayAuthUtils.AWS4 + secretKey, datetime.substr(0, 8)), region), service),
      ApiGatewayAuthUtils.AWS4_REQUEST
    );

    if (this.DEBUG) {
      console.log('[APIGatewayAuth] Signature - signinKey :', signinKey);
    }

    return signinKey;
  }

  protected calculateSignature(key, stringToSign) {
    const signature = this.hexEncode(this.hmac(key, stringToSign));

    if (this.DEBUG) {
      console.log('[APIGatewayAuth] Signature - signature :', signature);
    }

    return signature;
  }

  protected buildAuthorizationHeader(accessKey, credentialScope, headers, signature) {
    const authorizationHeader =
      ApiGatewayAuthUtils.AWS_SHA_256 +
      ' Credential=' +
      accessKey +
      '/' +
      credentialScope +
      ', SignedHeaders=' +
      this.buildCanonicalSignedHeaders(headers) +
      ', Signature=' +
      signature;

    if (this.DEBUG) {
      console.log('[APIGatewayAuth] Signature - authorizationHeader :', authorizationHeader);
    }

    return authorizationHeader;
  }

  /**
   * Extract headers as object from HttpHeaders
   * @param {HttpHeaders | HttpParams} httpCollection
   * @returns {{}}
   */
  protected fromHttpCollection(httpCollection: HttpHeaders | HttpParams): any {
    const object = {};
    for (const key of httpCollection.keys()) {
      object[key] = httpCollection.get(key);
    }
    return object;
  }

  /**
   * update the request Headers with Authorization, x_amz_date, x_amz_security,
   * and other required ApiGateway headers
   *
   * Ref : http://docs.aws.amazon.com/apigateway/api-reference/signing-requests/
   * http://docs.aws.amazon.com/fr_fr/AmazonS3/latest/API/sig-v4-header-based-auth.html
   *
   * @param {HttpRequest<any>} request
   * @param {AuthenticationDetails} authParameters
   */
  getHeadersForApiGAuth(request: HttpRequest<any>, authParameters?: AuthenticationDetails): any {
    if (this.DEBUG) {
      console.log('** Début calcul authorisation ***');
    }
    // Unauthenticated authParams
    if (!authParameters) {
      const authResult = this._injector.get(AWS_AUTH_DETAILS);

      authParameters = {
        authResult: { ...authResult },
        utilisateur: null,
      };
    }
    const verb = request.method;
    const endpoint = /(^https?:\/\/[^\/]+)/g.exec(request.url)[1];
    const path = request.url.substr(endpoint.length);

    // extract headers from request (for signature preparation)
    const headers = this.fromHttpCollection(request.headers);

    // If the user has not specified an override for Content type the use default
    if (!headers.hasOwnProperty('Content-Type')) {
      headers['Content-Type'] = this.apiConfig.defaultContentType;
    }

    // If the user has not specified an override for Accept type the use default
    if (!headers.hasOwnProperty('Accept')) {
      headers['Accept'] = this.apiConfig.defaultAcceptType;
    }

    let body = request.body;
    if (body && typeof body === typeof {}) {
      body = JSON.stringify(body);
      request = request.clone({ body });
    }

    // If there is no body remove the content-type header so it is not included in SigV4 calculation
    if (body === '' || body === undefined || body === null) {
      delete headers['Content-Type'];
    }

    // Prepare x_amz_date header
    const datetime = new Date(new Date().getTime() + this.apiConfig.systemClockOffset)
      .toISOString()
      .replace(/\.\d{3}Z$/, 'Z')
      .replace(/[:\-]|\.\d{3}/g, '');
    headers[ApiGatewayAuthUtils.X_AMZ_DATE] = datetime;

    // Add host header in signin parameters
    const parser = Url.parse(endpoint);
    headers[ApiGatewayAuthUtils.HOST] = parser.hostname;

    const queryParams = this.fromHttpCollection(request.params);

    // Prepare Signature parts
    const canonicalRequest = this.buildCanonicalRequest(verb, path, queryParams, headers, body);
    const hashedCanonicalRequest = this.hashCanonicalRequest(canonicalRequest);
    const credentialScope = this.buildCredentialScope(datetime, authParameters.authResult.awsRegion, this.apiConfig.service);
    const stringToSign = this.buildStringToSign(datetime, credentialScope, hashedCanonicalRequest);
    const signingKey = this.calculateSigningKey(
      authParameters.authResult.secretAccessKey,
      datetime,
      authParameters.authResult.awsRegion,
      this.apiConfig.service
    );

    // Add signature to headers (Authorization)
    const signature = this.calculateSignature(signingKey, stringToSign);
    headers[ApiGatewayAuthUtils.AUTHORIZATION] = this.buildAuthorizationHeader(
      authParameters.authResult.accessKeyId,
      credentialScope,
      headers,
      signature
    );

    // Add security token if session token is set
    if (authParameters.authResult.sessionToken !== undefined && authParameters.authResult.sessionToken !== '') {
      headers[ApiGatewayAuthUtils.X_AMZ_SECURITY_TOKEN] = authParameters.authResult.sessionToken;
    }

    // Need to re-attach Content-Type if it is not specified at this point
    if (!headers.hasOwnProperty('Content-Type')) {
      headers['Content-Type'] = this.apiConfig.defaultContentType;
    }

    delete headers[ApiGatewayAuthUtils.HOST];

    if (this.DEBUG) {
      console.log('** Fin calcul authorisation ***');
    }

    return headers;
  }
}
