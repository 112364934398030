export class PatternUtils {
  static matchesFully(value: string, pattern: RegExp) {
    if (value === undefined) {
      return false;
    }
    const match = value.match(pattern);
    return match !== null && this.isFullMatch(match);
  }

  static isFullMatch(match: RegExpMatchArray) {
    return match.input.length === match[0].length;
  }
}
