import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  static MESSAGE_STYLE_WARNING = 'warning';
  static MESSAGE_STYLE_SUCCESS = 'success';
  static MESSAGE_STYLE_ERROR = 'error';
  static MESSAGE_STYLE_INFO = 'info';
  static MESSAGE_DEFAULT_TIMEOUT = 5000;

  constructor(private translateService: TranslateService, private snackBar: MatSnackBar) {}

  /**
   * Affiche un message d'erreur
   * @param message
   * @param actionTitle
   * @param timeout par défaut MESSAGE_DEFAULT_TIMEOUT (5sec)
   */
  error(message, actionTitle?: string, timeout = MessageService.MESSAGE_DEFAULT_TIMEOUT): Observable<MatSnackBarRef<SimpleSnackBar>> {
    return this.show(message, MessageService.MESSAGE_STYLE_ERROR, actionTitle, timeout);
  }

  /**
   * Affiche un message de warning
   * @param message
   * @param actionTitle
   * @param timeout par défaut MESSAGE_DEFAULT_TIMEOUT (5sec)
   */
  warning(message, actionTitle?: string, timeout = MessageService.MESSAGE_DEFAULT_TIMEOUT): Observable<MatSnackBarRef<SimpleSnackBar>> {
    return this.show(message, MessageService.MESSAGE_STYLE_WARNING, actionTitle, timeout);
  }

  /**
   * Affiche une information
   * @param message
   * @param actionTitle
   * @param timeout par défaut MESSAGE_DEFAULT_TIMEOUT (5sec)
   */
  info(message, actionTitle?: string, timeout = MessageService.MESSAGE_DEFAULT_TIMEOUT): Observable<MatSnackBarRef<SimpleSnackBar>> {
    return this.show(message, MessageService.MESSAGE_STYLE_INFO, actionTitle, timeout);
  }

  /**
   * Affiche un succés
   * @param message
   * @param actionTitle
   * @param timeout par défaut MESSAGE_DEFAULT_TIMEOUT (5sec)
   */
  success(message, actionTitle?: string, timeout = MessageService.MESSAGE_DEFAULT_TIMEOUT): Observable<MatSnackBarRef<SimpleSnackBar>> {
    return this.show(message, MessageService.MESSAGE_STYLE_SUCCESS, actionTitle, timeout);
  }

  /**
   * Effectue l'action d'affichage
   * @param message
   * @param style
   * @param {string} actionTitle
   * @param {number} timeout
   * @returns {Observable<MatSnackBarRef<SimpleSnackBar>>}
   */
  protected show(
    message,
    style,
    actionTitle?: string,
    timeout = MessageService.MESSAGE_DEFAULT_TIMEOUT
  ): Observable<MatSnackBarRef<SimpleSnackBar>> {
    return this.translateService
      .get('ERREURS.OK')
      .pipe(map((action) => this.snackBar.open(message, action, { duration: timeout, panelClass: [style] })));
  }
}
