export class HttpError {
  public static HTTP_CODE_SUCCESS = 200;
  public static HTTP_CODE_CREATED = 201;
  public static HTTP_CODE_NO_CONTENT = 204;
  public static HTTP_CODE_BAD_REQUEST = 400;
  public static HTTP_CODE_UNAUTHENTICATED = 401;
  public static HTTP_CODE_UNAUTHORISED = 403;
  public static HTTP_CODE_NOT_FOUND = 404;
  public static HTTP_CODE_CONFLICT = 409;
  public static HTTP_CODE_SERVER_ERROR = 500;
}
