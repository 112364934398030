import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URI } from '../../../auth.type';
import { AuthenticationDetails } from '../models/authentication-details.model';

const URI = {
  AUTH: {
    BASE: (baseUri) => `${baseUri}/authentication`,
  },
};

@Injectable()
export class AuthProvider {
  constructor(private injector: Injector, @Inject(BASE_URI) private baseUri: string) {}

  refresh(authDetails: AuthenticationDetails): Observable<AuthenticationDetails> {
    return this.injector.get(HttpClient).put<AuthenticationDetails>(URI.AUTH.BASE(this.baseUri), {
      RefreshToken: authDetails.authResult.RefreshToken,
    });
  }

  login(username: string, password: string): Observable<AuthenticationDetails> {
    return this.injector.get(HttpClient).post<AuthenticationDetails>(URI.AUTH.BASE(this.baseUri), { username, password });
  }
}
