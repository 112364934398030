import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilisateurService } from '../user/utilisateur.service';
import { StarRouterService } from '@terega-portal/shared-lib';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(private routerService: StarRouterService, private utilisateurService: UtilisateurService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.utilisateurService.estConnecte().pipe(
      map((utilisateurConnecte) => {
        if (!utilisateurConnecte) {
          console.log('AuthenticationGuard: Utilisateur non connecté, redirection ');
          this.routerService.navigate(['/star-app/connexion']);
        }
        return utilisateurConnecte;
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (route.routeConfig.path.match('^activer')) {
      console.log('Autorisé sans connexion');
      return true;
    } else {
      return this.canActivate(route, state);
    }
  }
}
