import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

/**
 * listen route change and send update page to google analytics
 */
@Injectable({ providedIn: 'root' })
export class RouterEventsGAListener {
  subscription: Subscription;

  listen(router: Router): void {
    this.subscription = router.events
      .pipe(
        distinctUntilChanged((previous: any, current: any) => {
          // Subscribe to any `NavigationEnd` events where the url has changed
          if (current instanceof NavigationEnd) {
            return previous.url === current.url;
          }
          return true;
        })
      )
      .subscribe((event) => {
        gtag('config', environment.googleAnalyticsId, { page_path: event.url });
      });
  }

  clear(): void {
    this.subscription.unsubscribe();
  }
}
