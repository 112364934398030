<div [ngClass]="{'active': isSubMenuActive}" class="user-sub-menu">
  <div *ngIf="user" class="title">
    <div class="font-weight-light">
      {{ 'UI_HEADER.UTILISATEUR.SOUS_MENU.LIBELLE_TITRE' | translate }}
    </div>
    <div class="user-infos h1-like">
      <span name="firstname" class="user-prenom">{{ user?.prenom }}</span>
      <span name="lastname" class="user-nom">{{ user?.nom }}</span>
    </div>
  </div>

  <div class="separator">
    <div name="delegations" class="row" *ngIf="user">
      <div class="user-sub-menu__item-label" [ngClass]="{'col-sm-5': !user.hasConnectedClientProfile(), 'col-sm-4': user.hasConnectedClientProfile()}">
        {{ 'UI_HEADER.UTILISATEUR.SOUS_MENU.DELEGATION' | translate }}
      </div>
      <div class="user-sub-menu__item-data" [ngClass]="{'col-sm-7': !user.hasConnectedClientProfile(), 'col-sm-8': user.hasConnectedClientProfile()}">
        <span name="storage-delegation" *ngIf="user.hasDelegationStockage()" class="badge badge-pill badge-primary">
          {{'UI_HEADER.UTILISATEUR.PROFIL.OFFRE_STOCKAGE' | translate}}
        </span>
        <span name="transport-delegation" *ngIf="user.hasDelegationTransport()" class="badge badge-pill badge-primary">
          {{'UI_HEADER.UTILISATEUR.PROFIL.OFFRE_TRANSPORT' | translate}}
        </span>
        <span name="no-delegation" *ngIf="!user.hasDelegationStockage() && !user.hasDelegationTransport()" class="badge badge-pill badge-primary">
          {{'UI_HEADER.UTILISATEUR.PROFIL.AUCUNE' | translate}}
        </span>
      </div>
    </div>

    <div name="profile" class="row" *ngIf="user">
      <div class="user-sub-menu__item-label" [ngClass]="{'col-sm-5': !user.hasConnectedClientProfile(), 'col-sm-4': user.hasConnectedClientProfile()}">
        {{ 'UI_HEADER.UTILISATEUR.SOUS_MENU.PROFIL' | translate }}
      </div>
      <div class="user-sub-menu__item-data" [ngClass]="{'col-sm-7': !user.hasConnectedClientProfile(), 'col-sm-8': user.hasConnectedClientProfile()}">
        <span>
            {{ 'UI_HEADER.UTILISATEUR.PROFIL.' + user.habilitations.profil | translate }}
        </span>
      </div>
    </div>

    <div name="entity" *ngIf="user && !user.hasConnectedClientProfile()" class="row">
      <div class="col-sm-5 user-sub-menu__item-label">
        {{ 'UI_HEADER.UTILISATEUR.SOUS_MENU.SOCIETE' | translate }}
      </div>
      <div class="col-sm-7 user-sub-menu__item-data">
        <span>{{ user.entite?.nom }}</span>
      </div>
    </div>

    <div name="pics" *ngIf="user && user.hasConnectedClientProfile()" class="row">
      <div class="col-sm-4 user-sub-menu__item-label">
        {{ 'UI_HEADER.UTILISATEUR.SOUS_MENU.STATIONS' | translate }}
      </div>
      <div class="col-sm-8 user-sub-menu__item-data">
        <span *ngFor="let station of user.habilitations.stations" class="pics">
          {{ station.codePoint }} - {{ station.stationName }}
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="user" class="links">
    <a name="update-password" href="" starRouterLink="/star-app/motdepasse/modifier/">
      {{ 'UI_HEADER.MODIFICATION_PASSWORD_LIBELLE' | translate }}
    </a>
  </div>

  <div *ngIf="user" class="disconnect align-center">
    <button name="disconnect" (click)="disconnectUser()" class="auth-action" color="primary" mat-raised-button>
      {{ 'UI_HEADER.DECONNEXION' | translate }}
    </button>
  </div>
</div>
