import { Component, Input } from '@angular/core';
import { Config } from '@terega-portal/shared-lib';
import { CMSFrontImg } from '../misc-front-img/cms-misc-front-img.component';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'cms-misc-document',
  templateUrl: './cms-misc-document.component.html',
  styleUrls: ['./cms-misc-document.component.scss'],
})
export class CMSMiscDocumentComponent {
  @Input('componentData') public componentData: CMSDocument[];

  constructor(private config: Config) {}

  public toAbsoluteUrl(url: string): string {
    return this.isAbsolute(url) ? url : environment.cms.basepath;
  }

  /**
   * If the URL starts with a protocol or a double forward slash, then it is deemed absolute.
   *
   * @example <caption>The following examples return true:</caption>
   * mailto:mail[at]example.org, HTTPS://example.org, //file.jpg, XyZ:
   *
   * @example <caption>The following examples return false:</caption>
   * mail[at]example.org, +3360889, file.jpg, :
   *
   * @returns {boolean} True if the URL is qualified "absolute"
   */
  private isAbsolute(url: string): boolean {
    return new RegExp('^(([a-z]+:)|//)', 'i').test(url);
  }
}

export type CMSDocument =
  | CMSDocumentDocument
  | CMSDocumentParagraph
  | CMSDocumentInternalLink
  | CMSDocumentAssetLink
  | CMSDocumentExternalLink
  | CMSDocumentUnorderedList
  | CMSDocumentOrderedList
  | CMSDocumentListItem
  | CMSDocumentEmbeddedEntryBlock
  | CMSDocumentText;

interface CMSDocumentDocument {
  content: CMSDocument[];
  nodeType: 'document';
}

interface CMSDocumentParagraph {
  content: CMSDocument[];
  nodeType: 'paragraph';
}

interface CMSDocumentText {
  value: string;
  nodeType: 'text';
}

interface CMSDocumentInternalLink {
  content: CMSDocument[];
  data: {
    target: {
      fields: {
        slug: string;
      };
    };
  };
  nodeType: 'entry-hyperlink';
}

interface CMSDocumentAssetLink {
  data: {
    target: {
      sys: {
        id: string;
      };
    };
  };
  content: CMSDocument[];
  nodeType: 'asset-hyperlink';
}

interface CMSDocumentExternalLink {
  content: CMSDocument[];
  data: {
    uri: string;
  };
  nodeType: 'hyperlink';
}

interface CMSDocumentUnorderedList {
  content: CMSDocument[];
  nodeType: 'unordered-list';
}

interface CMSDocumentOrderedList {
  content: CMSDocument[];
  nodeType: 'unordered-list';
}

interface CMSDocumentListItem {
  content: CMSDocument[];
  nodeType: 'list-item';
}

interface CMSDocumentEmbeddedEntryBlock {
  data: CMSFrontImg;
  nodeType: 'embedded-entry-block';
}
