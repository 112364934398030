/* eslint "@typescript-eslint/consistent-type-assertions" : "off" */
export enum Profil {
  CLIENT = <any>'CLIENT',
  CLIENT_RACCORDE = <any>'CLIENT_RACCORDE',
  PROSPECT = <any>'PROSPECT',
  ADMINISTRATEUR = <any>'ADMINISTRATEUR',
  OBSERVATEUR = <any>'OBSERVATEUR',
}

export type ProfilWithDelegation = {
  profil: Profil;
  transport: boolean;
  stockage: boolean;
};
