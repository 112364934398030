import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { ENCH_UTILISATEURS_PASSWORD_POLICY } from '../user/utilisateur';

@Directive({
  selector: '[passwordPolicy][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordPolicyDirective, multi: true }],
})
export class PasswordPolicyDirective implements Validator {
  @Input() passwordPolicy: string;
  private policyPattern = ENCH_UTILISATEURS_PASSWORD_POLICY;

  validate(control: AbstractControl): { [key: string]: any } {
    return this.validatePolicy(control) ? null : { badPolicy: true };
  }

  validatePolicy(control: AbstractControl) {
    return control.value ? control.value.match(this.policyPattern) : true;
  }
}
