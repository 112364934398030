import { Component, OnInit } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'star-header-hour',
  templateUrl: './header-hour.component.html',
  styleUrls: ['./header-hour.component.scss'],
})
export class HeaderHourComponent implements OnInit {
  localHour: Observable<{ date: Date; offset: number }>;

  ngOnInit() {
    this.localHour = timer(1000, 10000).pipe(
      map(() => ({
        date: new Date(),
        offset: new Date().getTimezoneOffset() / -60,
      }))
    );
  }
}
