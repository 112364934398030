import { Injectable } from '@angular/core';
import { MENU } from '../menu/config/menu';
import { CommonMenu } from '../menu/menu.model';
import { BREADCRUMB_OUTSIDE_MENU } from './breadcrumb-outside-menu';
import { FeatureFlippingService } from '@terega-portal/shared-lib';
import { UtilisateurService, Profil } from '@terega-portal/auth-lib';
import { MenuService } from '../menu/menu.service';
import { Observable, map } from 'rxjs';

export type BreadcrumbMap = { [k: string]: BreadcrumbValue };
export type BreadcrumbValue = { breadcrumb: string[]; value?: CommonMenu };
@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  breadcrumbMap$: Observable<BreadcrumbMap>;

  constructor(
    private userService: UtilisateurService,
    private featureFlippingService: FeatureFlippingService,
    private menuService: MenuService
  ) {
    this.breadcrumbMap$ = this.menuService.tetraLinksAllowed$.pipe(
      map((tetraLinks) => {
        const breadcrumbFromMenu = MENU.reduce((acc, curr) => ({ ...acc, ...this.parseMenu(curr, {}, [], tetraLinks) }), {});
        return { ...breadcrumbFromMenu, ...BREADCRUMB_OUTSIDE_MENU };
      })
    );
  }

  parseMenu(menu: CommonMenu, breadcrumbMap: BreadcrumbMap, breadcrumb: string[], tetraLinks: string[]): BreadcrumbMap {
    if (this.hasAccessToMenuItem(menu, tetraLinks)) {
      const newBreadcrumb = [...breadcrumb, ...(menu.name && [menu.name])];
      if ('items' in menu) {
        menu?.items?.forEach((item) => this.parseMenu(item, breadcrumbMap, newBreadcrumb, tetraLinks));
      } else {
        breadcrumbMap[menu.name] = {
          breadcrumb: newBreadcrumb,
          value: menu,
        };
      }
    }
    return breadcrumbMap;
  }

  hasAccessToMenuItem(menu: CommonMenu, tetraLinks: string[]): boolean {
    return !(this.isFeatureFlippedFalse(menu) || this.userDoesntHaveRights(menu) || this.userDoesntHaveTetraAccess(menu, tetraLinks));
  }

  private isFeatureFlippedFalse = (menu: CommonMenu) =>
    !this.userService.hasAccess([Profil.ADMINISTRATEUR]) && this.featureFlippingService.get(menu.featureFlippingKey) === false;

  private userDoesntHaveRights = (menu: CommonMenu) => menu.authorizedProfiles && !this.userService.hasAccess(menu.authorizedProfiles);

  private userDoesntHaveTetraAccess = (menu: CommonMenu, tetraLinks: string[]) =>
    'tetraLink' in menu && !menu.tetraLink.startsWith('public') && !this.menuService.isLinkInTetraRights(menu.tetraLink, tetraLinks);
}
