export class SimulationMonth {
  validated: Array<SimulationMonthModel>;
  not_validated: Array<SimulationMonthModel>;
}

export class SimulationMonthModel {
  month: number;
  ecart_bilan_mensuel_positif: number;
  ecart_bilan_mensuel_negatif: number;
  du: number;
  du_set: number;
  set_price: number;
  nb_days_set: number;

  constructor(
    month: number,
    ecart_bilan_mensuel_positif: number,
    ecart_bilan_mensuel_negatif: number,
    du: number,
    du_set: number,
    set_price: number,
    nb_days_set: number
  ) {
    this.month = month;
    this.ecart_bilan_mensuel_negatif = ecart_bilan_mensuel_negatif;
    this.ecart_bilan_mensuel_positif = ecart_bilan_mensuel_positif;
    this.du = du;
    this.du_set = du_set;
    this.set_price = set_price;
    this.nb_days_set = nb_days_set;
  }
}

export class SimulationTable {
  code_entite?: number;
  is_validated: boolean;
  desequilibres: Array<Desiquilibre>;
}

export class DesequilibreBuilder {
  private static EDITABLE_NB_DATA_NAMES = [
    'prix_moyen_pondere',
    'prix_vente',
    'prix_achat',
    'ebj',
    'prix_neutre',
    'mte',
    'bordereaux',
    'mte_set',
    'bordereaux_set',
  ];

  constructor(private built: Desiquilibre) {}

  public withDate(date: string) {
    this.built.journee = date;
    return this;
  }

  public withEligibiliteSet(eligibilite_set: boolean, correspondingExistingData?: Desiquilibre) {
    this.built.eligibilite_set = {
      value: eligibilite_set,
      is_modified: this.getModifiedAttribute(correspondingExistingData, 'eligibilite_set'),
    };
    return this;
  }

  /**
   * construction dynamique de l'atribut [dataName]. on construit
   * obj[dataName] = {value: [valueNumber], is_modified: [valeur is_modified de la ligne existante]}
   * @param valueNumber
   * @param dataName
   * @param correspondingExistingData
   */
  public withEditableNbData(valueNumber: number, dataName: string, correspondingExistingData?: Desiquilibre) {
    if (DesequilibreBuilder.EDITABLE_NB_DATA_NAMES.indexOf(dataName) > -1) {
      this.built[dataName] = {
        value: valueNumber,
        is_modified: this.getModifiedAttribute(correspondingExistingData, dataName),
      };
    }
    return this;
  }

  public withSimulated(is_simulated: boolean) {
    this.built.is_simulated = is_simulated;
    return this;
  }

  private getModifiedAttribute(correspondingExistingData: any, attributeName: string): boolean {
    return correspondingExistingData ? correspondingExistingData[attributeName].is_modified : false;
  }

  public build(): Desiquilibre {
    return this.built;
  }
}

export class Desiquilibre {
  is_simulated: boolean;
  journee: string;
  prix_moyen_pondere: {
    value: number;
    is_modified: boolean;
  };
  prix_vente: {
    value: number;
    is_modified: boolean;
  };
  prix_achat: {
    value: number;
    is_modified: boolean;
  };
  eligibilite_set: {
    value: boolean;
    is_modified: boolean;
  };
  ebj: {
    value: number;
    is_modified: boolean;
  };
  prix_neutre: {
    value: number;
    is_modified: boolean;
  };
  mte: {
    value: number;
    is_modified: boolean;
  };
  bordereaux: {
    value: number;
    is_modified: boolean;
  };
  mte_set: {
    value: number;
    is_modified: boolean;
  };
  bordereaux_set: {
    value: number;
    is_modified: boolean;
  };
  static builder(): DesequilibreBuilder {
    return new DesequilibreBuilder(new Desiquilibre());
  }
}
