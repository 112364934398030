import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Utilisateur, UtilisateurService } from '@terega-portal/auth-lib';
import { StarEventBroadcasterService, StarEventType, StarRouterService } from '@terega-portal/shared-lib';

@Component({
  selector: 'star-header-user-sub-menu',
  templateUrl: './header-user-sub-menu.component.html',
  styleUrls: ['./header-user-sub-menu.component.scss'],
})
export class HeaderUserSubMenuComponent implements OnInit, OnDestroy {
  user: Utilisateur;
  userChangedSubscription;
  @Input() isSubMenuActive = false;

  constructor(
    public utilisateurService: UtilisateurService,
    private starEventBroadcaster: StarEventBroadcasterService,
    private routerService: StarRouterService
  ) {}

  ngOnInit(): void {
    this.utilisateurService.estConnecte();
    this.initUserWithProfil();

    this.userChangedSubscription = this.starEventBroadcaster.listen(StarEventType.USER_CHANGED).subscribe(() => this.initUserWithProfil());
  }

  ngOnDestroy(): void {
    this.userChangedSubscription.unsubscribe();
  }

  private initUserWithProfil(): void {
    this.utilisateurService.getConnectedUtilisateur().subscribe((user: Utilisateur) => {
      this.user = user;
    });
  }

  disconnectUser() {
    this.utilisateurService.deconnexion().subscribe(
      () => {
        this.user = null;
        this.starEventBroadcaster.send(StarEventType.USER_CHANGED);
        return this.routerService.navigate(['/app/']);
      },
      () => {
        return this.routerService.navigate(['/app/']);
      }
    );
  }
}
