<div class="align-right"><a (click)="onClickCancel()"><mat-icon >close</mat-icon></a></div>
<div class="m20 mat-typography">
  <div class="mat-display-3 align-center">{{message | translate}}</div>
  <div class="pure-g">
    <div class="pure-u-1-2 align-left">
      <button mat-raised-button class="cancel" color="secondary" (click)="onClickCancel()">
        <mat-icon>keyboard_arrow_left</mat-icon>{{ buttontCancelLabel | translate }}
      </button>
    </div>
    <div class="pure-u-1-2 align-right">
      <button mat-raised-button color="primary" (click)="onClickConfirm()">
        <mat-icon>check</mat-icon>{{ buttontConfirmLabel | translate }}
      </button>
    </div>
  </div>
</div>
