<footer>
  <div class="footer">
    <div class="d-flex justify-content-between footer__content">
      <div class="footer__block">
        <div class="d-flex flex-column">
          <span class="block-title">{{ 'UI_FOOTER.TITLE.TEREGA_SITES_DATA' | translate }}</span>
          <nav class="footer__menu d-flex align-items-center">
            <a name="link-terega" href="{{ teregaLink }}" target="_blank" class="bold-link">terega.fr </a>
            <span class="separator">•</span>
            <a name="link-our-api" href="/app/our-api"
               class="bold-link">{{ 'UI_FOOTER.TITLE.OUR_API' | translate }}</a>
          </nav>
          <div class="footer__mentions">
            <span>Teréga &copy; 2020</span>
            <span> | </span>
            <a name="link-ml" href="/star-app/conditions-generales-utilisation">{{
              'UI_FOOTER.CONDITIONS_GENERALES_UTILISATION' | translate
              }}</a>
          </div>
        </div>
      </div>
      <div class="vertical-separator"></div>
      <div class="footer__block">
        <div class="d-flex flex-column">
          <span class="block-title">{{ 'UI_FOOTER.TITLE.CONTACT_US' | translate }}</span>
          <a name="link-contact" href="/star-app/edito/contacts"
             class="bold-link">{{ 'UI_FOOTER.CONTACT' | translate }}</a>
          <div class="address d-flex flex-column">
            <span class="text">Espace Volta</span>
            <span>40 avenue de l’Europe, 64010 PAU CEDEX</span>
          </div>
        </div>
      </div>
      <div class="vertical-separator"></div>
      <div class="footer__block">
        <div class="d-flex flex-column">
          <span class="block-title">{{ 'UI_FOOTER.TITLE.OUR_TEAM_AT_YOUR_SERVICE' | translate }}</span>
          <div class="d-flex blue-sky align-items-center justify-content-between">
            <span class="phone-text">{{ 'UI_FOOTER.TEREGA_SWITCH_BOARD' | translate }}</span>
            <div class="phone-number d-flex">
              <mat-icon>phone</mat-icon>
              <div class="number">0 559 <span>133 400</span></div>
            </div>
          </div>
          <div class="d-flex green-apple-light align-items-center justify-content-between">
            <span class="phone-text">{{ 'UI_FOOTER.EMERGENCY_GAS_ONLY' | translate }}</span>
            <div class="phone-number d-flex">
              <mat-icon>phone</mat-icon>
              <div class="number">0 800 <span>028 800</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
