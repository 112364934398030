import { Platform } from '@angular/cdk/platform';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiGatewayAuthInterceptor, AuthModule, ErrorInterceptor } from '@terega-portal/auth-lib';
import { featureFlippingProviderFactory, FeatureFlippingService, StarSharedModule } from '@terega-portal/shared-lib';
import { UiModule } from '@terega-portal/ui-lib';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appTranslate } from './app.translate';
import { EventsModule } from './events/events.module';
import { SnackBarComponent } from './shared/components/snack-bar/snack-bar.component';
import { SnackBarService } from './shared/services/snack-bar.service';
import { EntityFilterComponent } from './shared/entities/entity-filter/entity-filter.component';
import { MaterialModule } from './shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { appInitializerFactory } from '@terega-portal/shared-lib';
import { CmsModule } from './shared/cms/cms.module';
import { DateFnsAdapter, DateFnsModule, MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { fr } from 'date-fns/locale';
import { DATE_FNS_LOCALE_MAP } from './shared/constants/mat-date-fns/date-fns-locale-map';
import { PORTAIL_MAT_DATE_FNS_FORMATS } from './shared/constants/mat-date-fns/mat-date-formats';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [AppComponent, SnackBarComponent, EntityFilterComponent],
  imports: [
    StarSharedModule.forRoot({ baseUri: environment.apiUrl }),
    UiModule.forRoot({ baseUri: environment.apiUrl, tetraLink: environment.teregaLink }),
    AuthModule.forRoot({ authDetails: environment.authDefaults, baseUri: environment.apiUrl }),
    TranslateModule.forRoot(appTranslate),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    EventsModule,
    HttpClientModule,
    MatNativeDateModule,
    DateFnsModule,
    MatDateFnsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CmsModule,
  ],
  entryComponents: [SnackBarComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: featureFlippingProviderFactory,
      deps: [FeatureFlippingService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiGatewayAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    {
      provide: LOCALE_ID,
      useValue: JSON.parse(localStorage.getItem('locale')) || 'fr',
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: DATE_FNS_LOCALE_MAP.get(JSON.parse(localStorage.getItem('locale'))) || fr,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: PORTAIL_MAT_DATE_FNS_FORMATS,
    },
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    DatePipe,
    SnackBarService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
