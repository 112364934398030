import { Utilisateur } from '../user/utilisateur';
import { Observable, of } from 'rxjs';
import { Profil } from '../auth/services/models/user-profiles.enum';

export class AuthServiceMock {
  public static getAuthDetails(): Observable<any> {
    const authenticationDetails = {
      authResult: {
        IdToken: '',
        AccessToken: '',
        RefreshToken: '',
        secretAccessKey: '',
        accessKeyId: '',
        sessionToken: '',
        awsRegion: '',
      },
      extraConf: '',
      utilisateur: null,
    };
    const user: Utilisateur = new Utilisateur();
    user.habilitations.profil = Profil.CLIENT;
    user.habilitations.hasDelegationOffreStockage = true;
    user.habilitations.hasDelegationOffreTransport = false;
    user.habilitations.stations = [];
    authenticationDetails.utilisateur = user;
    return of(authenticationDetails);
  }

  public static getTetraRight(): Observable<any> {
    const tetraRights = {
      links: [
        '/DonneesIntraJPrive',
        '/Capacites',
        '/DeclarationConjointeCreation',
        '/StockGazConduite',
        '/ListeGuide',
        '/Stockage',
        '/ServiceEquilibrageTransportJournal',
        '/PortefeuilleStockage',
        '/ListerGuichetCession',
        '/ListerOutilSimulation',
        '/ParametreSecurite',
        '/ListeStats',
        '/CreerDemande',
        '/ListeFlux',
        '/ListerAlertes',
        '/ListeCompte',
        '/CreerCession',
        '/Congestion',
        '/ListeTermesContractuels',
        '/MarcheCapacites',
        '/InfoVigilance',
        '/ListeContrat',
        '/CapaciteTRF',
        '/ListeJournalCession',
        '/Allocations',
        '/ListeFactureBordereau',
        '/ListeTraitementCession',
        '/ExportDonnees',
        '/LimitesTRF',
        '/ListeMaintenance',
        '/DeclarationConjointeJournal',
        '/ListeCapacitesNormalisees',
        '/ListeRatio',
        '/ServiceEquilibrageTransportCreation',
        '/InfoSpread',
        '/QualiteGaz',
        '/ListeHistorisation',
        '/Injecteur',
        '/ListeBordereaux',
        '/SuiviOperationnelEquilibrage',
        '/ReseauPhysique',
        '/ListeContratStockage',
        '/TauxCommercialisation',
        '/TableauDeBord',
        '/ListeJournalSimulationCession',
        '/FluxPhysiques',
        '/TauxExpediteur',
        '/ListerGuichetModifPIC',
        '/ListeProfil',
        '/ListeTraitement',
        '/Notifications',
        '/Equilibrage',
        '/ListeFaqLangue',
        '/ListeFacture',
        '/DeclarationConjointeBordereaux',
        '/ListeDocEspacePartage',
        '/ListeScenarioTRF',
        '/DelaiTraitement',
        '/ListeDocRedistribution',
        '/ListeFactureStockage',
        '/ListerGuichet',
        '/SimulationCession',
        '/ListeJournal',
        '/ListeParametrageMails',
        '/ListeTermesFactures',
        '/ListeFaq',
        '/SuiviOperationnelProgrammation',
        '/SuiviOperationnelStockage',
        '/ListeContratSpread',
        '/PrevisionClientsPrive',
        '/Travaux',
        '/DeclarationConjointeTransmission',
        '/ListeParametres',
        '/PointsCommerciaux',
      ],
    };
    return of(tetraRights);
  }
}
