import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbService } from '../breadcrumb/breadcrumb.service';
import { Observable, map, of } from 'rxjs';

@Component({
  selector: 'star-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  @Input() container: string;
  @Input() pageName: string;
  @Input() isCMS = false;
  breadcrumb$: Observable<string[]>;
  className: string;

  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.className = this.container ? `container-${this.container}` : 'container';
    this.breadcrumb$ = this.isCMS
      ? of([this.pageName])
      : this.breadcrumbService.breadcrumbMap$.pipe(map((breadcrumbMap) => breadcrumbMap[this.pageName]?.breadcrumb));
  }
}
