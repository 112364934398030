<div class="select" *ngIf="optionList?.length">
  <mat-form-field floatLabel="never"
                  [class.select-unclicked]="!selectedValue?.length">
    <mat-select [(value)]="selectedValue"
                (change)="onSelect()"
                [multiple]="multiple"
                [placeholder]="placeHolder | translate | uppercase">
      <mat-option *ngFor="let option of optionList" [value]="option.value">
        {{ option.text | translate }}
      </mat-option>
    </mat-select>
    <mat-icon class="arrow-down" matSuffix>keyboard_arrow_down</mat-icon>
  </mat-form-field>
</div>
