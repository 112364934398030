<div class="page">
  <section class="page__title">
    <div class="page__title__breadcrumb">
      <span *ngFor="let item of breadcrumb$ | async; let last = last" [ngClass]="{'page__title__page-name': last}">
        {{ isCMS ? item : 'UI_MENU.' + item | translate }}{{ last ? '' : ' / ' }}
      </span>
    </div>
    <img src="assets/img/page-title.svg">
  </section>
  <section class="page__container" [ngClass]="className">
    <ng-content></ng-content>
  </section>
</div>
