import { EventType } from '../enums/event-type.enum';
import { AbstractEventDetails } from './abstract-event-details.model';
import { EventDetailsFactory } from '../utils/event-details-factory';

export class StarEvent {
  public id: string;
  public type: EventType;
  public link: string;
  public creationDate: Date;
  public startDate: Date;
  public endDate: Date;
  public details: AbstractEventDetails;
  public readingStatus = true;
  public atiCode?: string;
  public point?: Point;

  fromJson(json: any) {
    this.id = json.id;
    this.type = json.type;
    this.link = json.link;
    this.startDate = json.startDate;
    this.creationDate = json.creationDate;
    this.endDate = json.endDate;
    this.details = EventDetailsFactory.constructDetails(json);
    this.readingStatus = json.read;
    this.atiCode = json.atiCode;
    this.point = json.point;
    return this;
  }
}

type Point = {
  code: string;
  label: string;
};
