import { Component, ContentChildren, ElementRef, Input, QueryList } from '@angular/core';

@Component({
  selector: 'star-dialog',
  templateUrl: './styled-dialog.component.html',
  styleUrls: ['./styled-dialog.component.scss'],
})
export class StyledDialogComponent {
  @Input() dialogTitle: string;
  @Input() backgroundIcon: string;
  @Input() disableClose = false;

  @ContentChildren('action') actions: QueryList<ElementRef>;
}
