import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URI } from '../shared.type';

export interface SsoToken {
  token: string;
}

const URI = {
  AUTH: {
    SSO: (baseUri) => `${baseUri}/authentication/sso_token`,
  },
};

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  public getTokenUrl: string;

  constructor(private http: HttpClient, @Inject(BASE_URI) private baseUri: string) {
    this.getTokenUrl = URI.AUTH.SSO(this.baseUri);
  }

  public getToken(): Observable<SsoToken> {
    return this.http.get<SsoToken>(this.getTokenUrl);
  }
}
