import {
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: '[collapsible-row]',
  templateUrl: './collapsible-row.component.html',
  styleUrls: ['./collapsible-row.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleRowComponent implements AfterViewInit {
  @Input() isOpen = false;
  @Input() collapseTemplate: TemplateRef<any>;
  @Input() context: Record<string, any>;
  @Input() collapseDisabled = false;
  colSpan = 0;

  @ViewChild('rowData') rowData: ElementRef;

  constructor(el: ElementRef, private cd: ChangeDetectorRef) {
    el.nativeElement.classList.add('collapsible-row');
  }

  ngAfterViewInit(): void {
    this.colSpan = this.rowData.nativeElement.children.length;
    this.cd.detectChanges();
  }

  toggleCollapse(): void {
    if (!this.collapseDisabled) {
      this.isOpen = !this.isOpen;
    }
  }
}
