import { Component, Input } from '@angular/core';
import { CMSComponentData, CMSComponent } from '../../../models/cms.model';
import { CMSCTA } from '../../cms-misc-components/misc-cta/cms-misc-cta.component';
import { CMSDocument } from '../../cms-misc-components/misc-document/cms-misc-document.component';

@Component({
  selector: 'cms-slice-fulltext',
  templateUrl: './cms-slice-fulltext.component.html',
  styleUrls: ['./cms-slice-fulltext.component.scss'],
})
export class CMSSliceFullTextComponent implements CMSComponent {
  @Input('componentData') readonly componentData: CMSFullText;
}

export interface CMSFullText extends CMSComponentData {
  fields: {
    title: string;
    content: CMSDocument; // Wrap in an array before passing to <cms-misc-document>
    cta: CMSCTA;
  };
}
