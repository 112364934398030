import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Event, EventsFilters } from './event.model';
import { URI } from '../../environments/uri';
import { Page } from '../shared/models/page';
import { format } from 'date-fns';

@Injectable()
export class EventsService {
  private numberOfUnreadEvents: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient) {}

  getNumberOfUnreadEvent(): Observable<number> {
    return this.numberOfUnreadEvents;
  }

  countEvents(codeEntite: number): void {
    this.http
      .post(URI.EVENTS.COUNT(codeEntite), {})
      .pipe(pluck('count'))
      .toPromise()
      .then((count) => {
        this.numberOfUnreadEvents.next(Number(count));
      });
  }

  findAll(filters: EventsFilters = { page: 0, size: 5 }, isConnected = false): Observable<Page<Event>> {
    let uriParams = `?page=${filters.page}&size=${filters.size}`;
    let uriBase = URI.EVENTS.PUBLIC;
    if (isConnected) {
      uriBase = URI.EVENTS.PRIVATE;
      if (!!filters.atiCode) {
        uriParams += `&atiCode=${filters.atiCode}`;
      }
    }
    if (!!filters.type) {
      uriParams += `&type=${filters.type}`;
    }
    if (!!filters.dateFrom) {
      uriParams += `&dateFrom=${format(filters.dateFrom, 'yyyy-MM-dd')}`;
    }
    if (!!filters.dateTo) {
      uriParams += `&dateTo=${format(filters.dateTo, 'yyyy-MM-dd')}`;
    }

    return this.http.get<Page<Event>>(uriBase + uriParams);
  }
}
