import { Component, Input } from '@angular/core';
import { CMSComponentData, CMSComponent } from '../../models/cms.model';
import { CMSDocument } from '../cms-misc-components/misc-document/cms-misc-document.component';

@Component({
  selector: 'cms-page',
  templateUrl: './cms-page.component.html',
  styleUrls: ['./cms-page.component.scss'],
})
export class CMSPageComponent implements CMSComponent {
  @Input('componentData') public readonly componentData: CMSPage;
}

export interface CMSPage extends CMSComponentData {
  fields: {
    breadcrumb: [
      {
        fields: {
          label: string;
        };
      }
    ];
    seoTitle: string;
    title: string;
    slug: string;
    chapo: CMSDocument; // Wrap in an array before passing to <cms-misc-document>
    slices: CMSComponentData[];
  };
}
