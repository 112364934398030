<div class="user-content">
  <div class="user-infos">
    <div *ngIf="user !== null ; else connexionPanel;" class="user-infos-container">
      <div class="pointer" (click)="onSubMenuClick()">
        <span class="user-nom uc qc-user-nom">{{ user?.nom }},</span>
        <span class="user-prenom qc-user-prenom">{{ user?.prenom }}</span>
      </div>
    </div>
    <ng-template #connexionPanel>
      <div>
        <a class="auth-action auth-connect" starRouterLink="/star-app/connexion">{{ 'UI_HEADER.CONNEXION' | translate }}</a>
      </div>
    </ng-template>
    <div class="user-infos__icon-wrapper pointer" (click)="onSubMenuClick()">
      <i class="material-icons">perm_identity</i>
    </div>
  </div>
  <star-header-user-sub-menu [isSubMenuActive]="activeMenu"></star-header-user-sub-menu>
</div>
