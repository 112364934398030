import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureFlippingService, StarRouterService } from '@terega-portal/shared-lib';
import { UtilisateurService } from '../user/utilisateur.service';
import { Profil } from '../auth/services/models/user-profiles.enum';
import { map } from 'rxjs/operators';

@Injectable()
export class FeatureFlippingGuard implements CanActivate {
  constructor(
    private routerService: StarRouterService,
    private featureFlippingService: FeatureFlippingService,
    private utilisateurService: UtilisateurService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.utilisateurService.getConnectedUtilisateur().pipe(
      map(() => {
        return (
          this.utilisateurService.hasAccess([Profil.ADMINISTRATEUR]) || !!this.featureFlippingService.get(route.data.featureFlippingKey)
        );
      })
    );
  }
}
