import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[mailValidator][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MailValidatorDirective, multi: true }],
})
export class MailValidatorDirective implements Validator {
  @Input() mailValidator: string;

  private pattern = /^(?:[a-z0-9_+-]\.?)+@(?:[a-z0-9_-]\.?)+\.[a-z]{2,}$/;

  validate(control: AbstractControl): { [key: string]: any } {
    return this.validateMail(control) ? null : { mailInvalid: true };
  }

  private validateMail(control: AbstractControl) {
    const regExp = RegExp(this.pattern);
    return control.value ? regExp.test(control.value) : true;
  }
}
