import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Menu } from './menu.model';
import { MENU } from './config/menu';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MenuService } from './menu.service';
import { NavigationEnd, Router } from '@angular/router';
import { Profil } from '@terega-portal/auth-lib';
import { TranslateService } from '@ngx-translate/core';
import { pluck, startWith } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'star-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('openCloseMenu', [
      state('open', style({ width: '222px' })),
      state('closed', style({ width: '64px' })),
      transition('open => closed', [animate('200ms 200ms ease')]),
      transition('closed => open', [animate('200ms ease')]),
    ]),
    trigger('openCloseSubMenu', [state('open', style({ opacity: 1 })), state('closed', style({ opacity: 0, display: 'none' }))]),
  ],
})
export class MenuComponent implements OnInit, OnDestroy {
  menus: Menu[];
  mouseOverMenu: boolean;
  selectedMenu: Menu;
  hoveredMenu: Menu;
  profile: Profil;
  lang: string;
  @Input()
  outsideClick: Observable<void>;
  private outsideClickSubscription: Subscription;
  @Output()
  onMenuStateChange = new EventEmitter<boolean>();

  constructor(private menuService: MenuService, private router: Router, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.initUserLang();
    this.menuService.downloadIcons();
    this.closeMenuIfLinkIsClickedByUser();
    this.outsideClickSubscription = this.outsideClick.subscribe(() => this.closeMenu());
  }

  private closeMenuIfLinkIsClickedByUser(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.closeMenu();
      }
    });
  }

  private closeMenu(): void {
    this.mouseOverMenu = false;
    this.selectedMenu = null;
    this.onMenuStateChange.next(false);
  }

  private initUserLang(): void {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.pipe(pluck('lang'), startWith(this.translateService.currentLang)).subscribe((lang: string) => {
      this.lang = lang;
      this.loadMenus();
    });
  }

  public migratedPages(externalLink: string): string {
    return externalLink?.includes('star-app') ? '_self' : '_blank';
  }

  private loadMenus(): void {
    this.menus = JSON.parse(JSON.stringify(MENU)).map((menu) => this.translateMenu(menu));
  }

  private translateMenu(menu): Menu {
    return {
      ...menu,
      name: menu.name && this.translateService.instant(`UI_MENU.${menu.name}`),
      ...(menu.items && { items: menu.items.map((submenu) => this.translateMenu(submenu)) }),
    };
  }

  ngOnDestroy(): void {
    this.outsideClickSubscription.unsubscribe();
  }
}
