<form class="entity-select-form">
  <mat-form-field class="w-100 mat-form-field-small" appearance="outline">
    <mat-icon matPrefix class="mr-2">business</mat-icon>
    <mat-label>{{'UI_FILTRES.ENTITE' | translate}}</mat-label>
    <input type="text" matInput [formControl]="entityControl" [matAutocomplete]="auto" [placeholder]="'UI_FILTRES.CHOOSE_ENTITY' | translate">

    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn"
                      (optionSelected)="optionSelected($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option.nom}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
