import { Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2 } from '@angular/core';
import { PatternUtils } from './pattern-utils';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[ngModel][star-patternRestrict]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PatternRestrictDirective,
      multi: true,
    },
  ],
})
export class PatternRestrictDirective implements ControlValueAccessor {
  private _onTouched: any;
  private _onChange: any;

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  constructor(private _elementRef: ElementRef) {}

  restrictToPattern(component) {
    const pattern = this.getRegexp(component);
    let transformed = component.value;

    if (component && component.value && !this.matchesFully(component, pattern)) {
      transformed = this.removeNotMatchCharacters(component.value, pattern);
    }

    return transformed;
  }

  private matchesFully(component, pattern: RegExp) {
    return !component.value || component.value.length === 0 || PatternUtils.matchesFully(component.value, pattern);
  }

  private removeNotMatchCharacters(value: string, pattern: RegExp): string {
    const charArray = value.split('');
    let result = '';

    for (let i = 0; i < charArray.length; i++) {
      if (PatternUtils.matchesFully(result + charArray[i], pattern)) {
        result = result + charArray[i];
      }
    }
    return result;
  }

  getRegexp(component) {
    const charpattern = this.getPattern(component);
    const pattern = new RegExp(charpattern);
    return pattern;
  }

  getPattern(component: any): string {
    const charpattern = component.attributes['charpattern'] ? component.attributes['charpattern'].value : component.pattern;
    return charpattern;
  }

  @HostListener('input', ['$event'])
  onHostInput($event) {
    // Correctif IE11 : au chargement de page IE detecte et déclenche un eventListener sur les inputs des formulaires
    // Pour éviter de lancer le traitement, on vérifie que $event.target.value n'est pas vide (donc qu'on à rien tapé)
    // ca évite de déclancher des erreurs au chargement de page [TIGF-876]
    if ($event.target.value) {
      const transformed = this.restrictToPattern($event.currentTarget);
      this.writeValue(transformed);
      this.ngModelChange.emit(transformed);
      this._onTouched();
    } else {
      this.ngModelChange.emit($event.target.value);
    }
  }

  @HostListener('change', ['$event'])
  onHostChange($event) {
    if ($event.target.value) {
      this._onChange($event.target.value);
    }
  }

  @HostListener('blur', ['$event'])
  onHostBlur($event) {
    if (this._onTouched) {
      this._onTouched($event);
    }
  }

  writeValue(value: any): void {
    const writeValue = value === undefined ? null : value;
    this._elementRef.nativeElement.value = writeValue;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
