import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AuthService, Utilisateur, UtilisateurService } from '@terega-portal/auth-lib';
import { StarEventBroadcasterService, StarEventType } from '@terega-portal/shared-lib';
import { catchError, Observable, of } from 'rxjs';
import { map, mergeMap, pluck, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { CustomIconService } from '../custom-icon/custom-icon.service';

@Injectable()
export class MenuService {
  tetraLinksAllowed$: Observable<any>;
  private connectedUser$: Observable<Utilisateur>;

  constructor(
    private materialIconService: CustomIconService,
    private starEventBroadcaster: StarEventBroadcasterService,
    private authService: AuthService,
    private utilisateurService: UtilisateurService,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {
    this.initService();
  }

  private initService(): void {
    this.tetraLinksAllowed$ = this.starEventBroadcaster.listen(StarEventType.USER_CHANGED).pipe(
      startWith(0),
      switchMap(() => this.utilisateurService.getConnectedUtilisateur()),
      mergeMap((user: Utilisateur) => {
        /*
        if (user) {
          return this.authService.getTetraRight().pipe(
            pluck('links'),
            catchError(() => of([]))
          );
        }
         */
        return of([]);
      }),
      shareReplay(1)
    );

    this.connectedUser$ = this.utilisateurService.getConnectedUtilisateur().pipe(shareReplay(1));
  }

  isTetraLinkAllowed(tetraLink: string): Observable<boolean> {
    return this.tetraLinksAllowed$.pipe(map((tetraLinksAllowed) => this.isLinkInTetraRights(tetraLink, tetraLinksAllowed)));
  }

  getConnectedUser(): Observable<Utilisateur> {
    return this.connectedUser$;
  }

  downloadIcons(): void {
    const baseHref = `${this.baseHref}/assets/common/icons`;
    this.materialIconService.addIcon('menu-homepage', `${baseHref}/menu-homepage.svg`);
    this.materialIconService.addIcon('menu-my-data', `${baseHref}/menu-my-data.svg`);
    this.materialIconService.addIcon('menu-transport', `${baseHref}/menu-transport.svg`);
    this.materialIconService.addIcon('menu-storage', `${baseHref}/menu-storage.svg`);
    this.materialIconService.addIcon('menu-connection', `${baseHref}/menu-connection.svg`);
    this.materialIconService.addIcon('menu-useful-links', `${baseHref}/menu-useful-links.svg`);
    this.materialIconService.addIcon('menu-admin', `${baseHref}/menu-admin.svg`);
    this.materialIconService.addIcon('menu-external-link', `${baseHref}/menu-external-link.svg`);
    this.materialIconService.addIcon('menu-contact', `${baseHref}/menu-contact.svg`);
    this.materialIconService.addIcon('terega-logo', `${baseHref}/terega-logo.svg`);
    this.materialIconService.addIcon('terega-title', `${baseHref}/terega-title.svg`);
  }

  public isLinkInTetraRights(tetraLink: string, tetraLinksAllowed: string[]): boolean {
    if (tetraLinksAllowed && tetraLink && Array.isArray(tetraLinksAllowed)) {
      return !!tetraLinksAllowed.find((l) => ('/' + tetraLink).indexOf(l) > -1);
    }
    return false;
  }
}
