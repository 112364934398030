import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({ providedIn: 'root' })
export class LocalStorageStarService {
  private key = '$ecretK3yT3reg@';

  constructor(private localStorageService: LocalStorageService) {}

  public setLocale(lang: string): string {
    this.localStorageService.store('locale', lang);
    return this.localStorageService.retrieve('locale');
  }

  public getLocale(): string {
    const locale = this.localStorageService.retrieve('locale');
    return locale == null ? '' : locale;
  }

  public set(key: string, value: any) {
    const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), this.key).toString();
    this.localStorageService.store(key, encryptedValue);
  }

  public get(key: string): any {
    const encryptedValue = this.localStorageService.retrieve(key);

    if (!encryptedValue) {
      return null;
    }

    const decoded = CryptoJS.AES.decrypt(encryptedValue, this.key).toString(CryptoJS.enc.Utf8);

    return JSON.parse(decoded);
  }

  public removeKey(key: string) {
    return this.localStorageService.clear(key);
  }
}
