import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

export class CustomLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of({ KEY: 'value' });
  }
}

export const testTranslate: any = {
  loader: { provide: TranslateLoader, useClass: CustomLoader },
};
