<tr #rowData [ngClass]="{'collapse-disabled': collapseDisabled}">
  <ng-content select="[rowData]"></ng-content>
  <td class="detail-button">
    <mat-icon *ngIf="!isOpen" (click)="toggleCollapse()">keyboard_arrow_down</mat-icon>
    <mat-icon *ngIf="isOpen" (click)="toggleCollapse()">keyboard_arrow_up</mat-icon>
  </td>
</tr>
<tr class="collapsed-row" *ngIf="!collapseDisabled">
  <td [colSpan]="colSpan" class="p-0">
    <ng-container *ngIf="isOpen">
      <ng-container *ngTemplateOutlet="collapseTemplate; context: context"></ng-container>
    </ng-container>
  </td>
</tr>
