import { EventType } from '../enums/event-type.enum';
import { AbstractEventDetails } from '../models/abstract-event-details.model';
import { ActionMarketImbalanceEventDetails } from '../models/action-market-imbalance-event-details.model';
import { EndSubscriptionEventDetails } from '../models/end-subscription-event-details.model';
import { PicDailyCapacityOverrunEventDetails } from '../models/pic-daily-capacity-overrun-event-details.model';
import { PicHourlyCapacityOverrunEventDetails } from '../models/pic-hourly-capacity-overrun-event-details.model';
import { ScheduledMaintenanceEventDetails } from '../models/scheduled-maintenance-event-details.model';

export class EventDetailsFactory {
  public static constructDetails(event: any): AbstractEventDetails {
    if (event.details) {
      switch (event.type) {
        case EventType.ACTION_MARKET_IMBALANCE:
          return new ActionMarketImbalanceEventDetails();
        case EventType.END_SUBSCRIPTION_PIC:
          return new EndSubscriptionEventDetails();
        case EventType.PIC_DAILY_CAPACITY_OVERRUN:
          return new PicDailyCapacityOverrunEventDetails();
        case EventType.PIC_HOURLY_CAPACITY_OVERRUN:
          return new PicHourlyCapacityOverrunEventDetails();
        case EventType.MAINTENANCE:
          const eventDetails = new ScheduledMaintenanceEventDetails();
          eventDetails.labels = {
            fr: event.details.labels.fr,
            en: event.details.labels.en,
          };
          eventDetails.nature = event.details.nature;
          eventDetails.type = event.details.type;
          return eventDetails;
        default:
          return null;
      }
    }
    return null;
  }
}
