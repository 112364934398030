import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { pluck, shareReplay, startWith, Subscription } from 'rxjs';

@Component({
  selector: 'star-sso-link',
  templateUrl: './sso-link.component.html',
  styleUrls: ['./sso-link.component.scss'],
})
export class SsoLinkComponent implements OnInit, OnDestroy {
  @Input() url: string;
  @Input() htmlType: string;
  @Input() cssClass = '';
  lang: string;
  private localeChangeSubscription: Subscription;
  private onLangChangeSubscription = new Subscription();

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.onLangChangeSubscription.add(
      this.translate.onLangChange.pipe(pluck('lang'), startWith(this.translate.currentLang), shareReplay()).subscribe((lang) => {
        this.lang = lang;
      })
    );
  }

  ngOnDestroy() {
    this.onLangChangeSubscription.unsubscribe();
  }

  private addLang(link: string): string {
    if (this.lang) {
      return link ? `${link}&lang=${this.lang}` : `${link}?lang=${this.lang}`;
    }
    return link;
  }

  getUrl = (): string => `/star-app/redirectTetraSso?tetraUri=${encodeURIComponent(this.addLang(this.url))}`;

  onClick(event) {
    // Disable default element click processing
    event.preventDefault();
    this.redirect(this.getUrl());
  }

  redirect(url: string) {
    window.open(url);
  }
}
