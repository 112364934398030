import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { pluck, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-test-page-cms',
  template: '<cms-content-loader *ngIf="!!pageID" [contentId]="pageID" [lang]="lang$ | async"></cms-content-loader>',
})
export class CMSEditoLoaderComponent implements OnInit, OnDestroy {
  public pageID: string;
  private subscription: Subscription;
  lang$: Observable<string>;

  constructor(private activatedRoute: ActivatedRoute, private translateService: TranslateService) {}

  ngOnInit() {
    this.lang$ = this.translateService.onLangChange.pipe(pluck('lang'), startWith(this.translateService.currentLang));
    this.subscription = this.activatedRoute.paramMap.subscribe((params) => {
      const newSlug = decodeURIComponent(params.get('slug'));
      if (this.pageID !== newSlug) {
        this.pageID = newSlug;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
