import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as jwtTokenDecode from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BASE_URI } from '../../auth.type';
import { Utilisateur } from '../../user/utilisateur';
import { AuthCachingService } from './auth-caching.service';
import { AuthenticationDetails } from './models/authentication-details.model';
import { AuthProvider } from './providers/auth.provider';

const jwtDecode: any = (jwtTokenDecode as any).default || jwtTokenDecode;

const URI = {
  ENTITY: {
    BASE: (baseUri, id) => `${baseUri}/entite/${id}`,
  },
  TETRA_AUTH: {
    BASE: (baseUri) => `${baseUri}/authentication/tetra_right`,
  },
};

@Injectable()
export class AuthService {
  public provider: AuthProvider;

  constructor(
    private customAuthProvider: AuthProvider,
    private authCaching: AuthCachingService,
    private http: HttpClient,
    @Inject(BASE_URI) private baseUri: string
  ) {
    this.provider = customAuthProvider;
  }

  public login(username: string, password: string): Observable<AuthenticationDetails> {
    return this.provider.login(username, password).pipe(switchMap((authDetails: AuthenticationDetails) => this.mapEntite(authDetails)));
  }

  private mapEntite(authDetails: AuthenticationDetails): Observable<AuthenticationDetails> {
    authDetails.utilisateur = new Utilisateur().fromData(authDetails.utilisateur);
    // Il est important de sauvegarder avant de faire l'appel au service entité car sinon cela provoque une boucle infinie.
    // Dans 'authDetails' se trouve le 'AccessToken' qui determinera la date d'expiration du token
    // et donc si l'on doit effectuer une mise à jour des 'AuthenticationDetails'.
    this.authCaching.setAuthDetails(authDetails);
    if (authDetails && authDetails.utilisateur && authDetails.utilisateur.entiteId) {
      return this.http.get(URI.ENTITY.BASE(this.baseUri, authDetails.utilisateur.entiteId)).pipe(
        map((entite: any) => {
          authDetails.utilisateur.entite = entite;
          this.authCaching.setAuthDetails(authDetails);
          return authDetails;
        })
      );
    }
    return of(authDetails);
  }

  public logout(): Observable<void> {
    this.authCaching.setAuthDetails(null);
    return of(null);
  }

  public getAuthDetails(): Observable<AuthenticationDetails> {
    const authDetails = this.authCaching.getAuthDetails();

    if (authDetails) {
      // Vérification de la date d'expiration du token et demande de refresh
      const decodedToken = jwtDecode(authDetails.authResult.AccessToken);
      const expirationDate = new Date(decodedToken['exp'] * 1000);
      if (new Date() >= expirationDate) {
        return this.refresh();
      }
    }
    return of(authDetails);
  }

  public getTetraRight(): Observable<string[]> {
    return this.http.get<string[]>(URI.TETRA_AUTH.BASE(this.baseUri));
  }

  public refresh(): Observable<AuthenticationDetails> {
    return this.provider.refresh(this.authCaching.getAuthDetails()).pipe(
      switchMap((authDetails: AuthenticationDetails) => this.mapEntite(authDetails)),
      catchError(() => {
        this.authCaching.setAuthDetails(null);
        return of(null);
      })
    );
  }
}
