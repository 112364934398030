import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, Observable, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TetraLinkPipe } from '../pipe/tetra-link.pipe';
import { CommonMenu } from '../menu/menu.model';
import { BreadcrumbMap, BreadcrumbService, BreadcrumbValue } from '../breadcrumb/breadcrumb.service';

type TranslatedMenuItem = { translatedMenu: string; translatedBreadcrumb: string; menuItem: BreadcrumbValue };

@Component({
  selector: 'app-menu-search-bar',
  templateUrl: './menu-search-bar.component.html',
  styleUrls: ['./menu-search-bar.component.scss'],
})
export class MenuSearchBarComponent implements OnInit {
  filteredTranslateItems$: Observable<TranslatedMenuItem[]>;
  searchControl = new FormControl();
  searchMinLength = 2;
  constructor(
    private breadcrumbService: BreadcrumbService,
    private translateService: TranslateService,
    private router: Router,
    private tetraLinkPipe: TetraLinkPipe
  ) {}

  ngOnInit(): void {
    this.filteredTranslateItems$ = this.breadcrumbService.breadcrumbMap$.pipe(
      switchMap((breadcrumbMap) =>
        this.translateService.onLangChange.pipe(
          startWith(null),
          switchMap(() => this.changeMapWhenLangChangeAndFilterSearch(breadcrumbMap))
        )
      )
    );
  }

  changePage(menuItem: CommonMenu) {
    if ('link' in menuItem) {
      this.router.navigate([menuItem.link]);
    } else if ('externalLink' in menuItem) {
      window.open(menuItem.externalLink, menuItem.externalLink?.includes('star-app') ? '_self' : '_blank');
    } else if ('resourceLink' in menuItem) {
      window.open(menuItem.resourceLink[this.translateService.currentLang], '_blank');
    } else if ('tetraLink' in menuItem) {
      window.open(this.tetraLinkPipe.transform(menuItem.tetraLink, this.translateService.currentLang), '_blank');
    }
  }

  displayItem(menuItem: TranslatedMenuItem) {
    return menuItem?.translatedMenu;
  }

  private changeMapWhenLangChangeAndFilterSearch = (breadcrumbMap: BreadcrumbMap) => {
    const allTranslatedMap = this.translateBreadcrumbMap(breadcrumbMap);
    return this.searchControl.valueChanges.pipe(
      startWith(this.searchControl.value || ''),
      debounceTime(200),
      map((searchTerm) => {
        return searchTerm && typeof searchTerm === 'string'
          ? allTranslatedMap.filter((translatedItem) => translatedItem.translatedMenu.toLowerCase().includes(searchTerm.toLowerCase()))
          : allTranslatedMap;
      })
    );
  };

  private translateBreadcrumbMap(breadcrumbMap: BreadcrumbMap): TranslatedMenuItem[] {
    return Object.entries(breadcrumbMap)
      .filter(
        ([, menuItem]) =>
          menuItem.value &&
          ('link' in menuItem.value ||
            'externalLink' in menuItem.value ||
            'tetraLink' in menuItem.value ||
            'resourceLink' in menuItem.value)
      )
      .map(([menuName, menuItem]) => ({
        translatedMenu: this.translateService.instant(`UI_MENU.${menuName}`),
        translatedBreadcrumb: menuItem.breadcrumb.map((menuName) => this.translateService.instant(`UI_MENU.${menuName}`)).join(' / '),
        menuItem,
      }));
  }
}
