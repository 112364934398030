import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlippingService } from '@terega-portal/shared-lib';
import { Profil, UtilisateurService } from '@terega-portal/auth-lib';

@Directive({
  selector: '[flippable]',
})
export class FlippableDirective {
  displayBlocks = false;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlippingService: FeatureFlippingService,
    private utilisateurService: UtilisateurService
  ) {}

  @Input()
  set flippable(key: string) {
    if (this.utilisateurService.hasAccess([Profil.ADMINISTRATEUR])) {
      this.displayBlocks = true;
    } else {
      this.displayBlocks = key && key.length ? !!this.featureFlippingService.get(key) : true;
    }
    this.updateView();
  }

  private updateView() {
    if (this.displayBlocks) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
