import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TETRA_LINK } from '../ui.type';

@Component({
  selector: 'star-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnDestroy, OnInit {
  teregaLink: string;
  onLangChangeSubscription: Subscription;
  lang: string;

  constructor(private translateService: TranslateService, @Inject(TETRA_LINK) private tetraLink: string) {}

  ngOnInit() {
    this.lang = this.translateService.defaultLang;
    this.updateTeregaLink();
    this.onLangChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = 'fr' === event.lang ? 'fr' : 'en';
      this.updateTeregaLink();
    });
  }

  ngOnDestroy() {
    this.onLangChangeSubscription.unsubscribe();
  }

  updateTeregaLink(): void {
    this.teregaLink = this.lang === 'fr' ? this.tetraLink : this.tetraLink + '/en/';
  }
}
