import { Component, Input } from '@angular/core';
import { CMSComponentData, CMSComponent } from '../../../models/cms.model';

@Component({
  selector: 'cms-misc-front-img',
  templateUrl: './cms-misc-front-img.component.html',
  styleUrls: ['./cms-misc-front-img.component.scss'],
})
export class CMSMiscFrontImgComponent implements CMSComponent {
  @Input('componentData') readonly componentData: CMSFrontImg;
}

export interface CMSFrontImg extends CMSComponentData {
  fields: {
    name: string;
    altImage?: string;
    legend?: string;
    file: {
      fields: {
        file: {
          contentType: string;
          details: {
            image: {
              width: number;
              height: number;
            };
            size: number;
          };
          fileName: string;
          url: string;
        };
        title: string;
      };
    };
  };
}
