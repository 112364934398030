import { Entite, Point } from '@terega-portal/shared-lib';
import { Delegation } from '../auth/services/models/delegation.enum';
import { Habilitations } from '../auth/services/models/habilitations.model';
import { Profil } from '../auth/services/models/user-profiles.enum';
import { UtilisateurStatut } from '../shared/utilisateur-statut.enum';

export const ENCH_UTILISATEURS_PASSWORD_POLICY = /^((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~'!@#$%?&*()_+={}|\[\]:";<>,.]).{8,16})$/;

export interface PasswordResetInformation {
  generationDate: string;
  passwordResetKey: string;
}

export class Utilisateur {
  id: string;
  nom: string;
  prenom: string;
  mail: string;
  fonction: string;
  societe: string;
  entiteId: string;
  entite: Entite;
  indicatif: string;
  telephone: string;
  statut: UtilisateurStatut;
  langue: string;
  motDePasse: string;
  passwordResetInformation?: PasswordResetInformation;
  habilitations: Habilitations;
  hasBeenActivated: boolean;

  constructor() {
    this.langue = 'fr';
    this.habilitations = new Habilitations();
  }

  fromData(data: any): Utilisateur {
    if (data.hasOwnProperty('id')) {
      this.id = data['id'];
    }
    if (data.hasOwnProperty('nom')) {
      this.nom = data['nom'];
    }
    if (data.hasOwnProperty('prenom')) {
      this.prenom = data['prenom'];
    }
    if (data.hasOwnProperty('mail')) {
      this.mail = data['mail'];
    }
    if (data.hasOwnProperty('fonction')) {
      this.fonction = data['fonction'];
    }
    if (data.hasOwnProperty('entiteId')) {
      this.entiteId = data['entiteId'];
    }
    if (data.hasOwnProperty('entite')) {
      this.entite = data['entite'];
    }
    if (data.hasOwnProperty('societe')) {
      this.societe = data['societe'];
    }
    if (data.hasOwnProperty('indicatif')) {
      this.indicatif = data['indicatif'];
    }
    if (data.hasOwnProperty('telephone')) {
      this.telephone = data['telephone'];
    }
    if (data.hasOwnProperty('langue')) {
      this.langue = data['langue'];
    }
    if (data.hasOwnProperty('statut')) {
      this.statut = data['statut'];
    }
    if (data.hasOwnProperty('passwordResetInformation')) {
      this.passwordResetInformation = data['passwordResetInformation'];
    }
    if (data.hasOwnProperty('hasBeenActivated')) {
      this.hasBeenActivated = data['hasBeenActivated'];
    }
    if (data.hasOwnProperty('habilitations')) {
      this.habilitations = data['habilitations'];
    }

    return this;
  }

  hasAccess(authorizedProfils: Profil[]) {
    return !!this.habilitations.profil && !!authorizedProfils.find((profil) => profil === this.habilitations.profil);
  }

  hasDelegation(delegation: Delegation) {
    if (delegation === Delegation.DELEGATION_OFFRE_STOCKAGE) {
      return this.habilitations.hasDelegationOffreStockage;
    }
    if (delegation === Delegation.DELEGATION_OFFRE_TRANSPORT) {
      return this.habilitations.hasDelegationOffreTransport;
    }
    return null;
  }

  isAdmin(): boolean {
    return this.hasAccess([Profil.ADMINISTRATEUR]);
  }

  isClientWithTransportDelegation(): boolean {
    return this.hasAccess([Profil.CLIENT, Profil.CLIENT_RACCORDE]) && this.habilitations.hasDelegationOffreTransport;
  }

  hasRole(profiles: Profil[]): boolean {
    return profiles.indexOf(this.habilitations.profil) > -1;
  }

  hasDelegationStockage(): boolean {
    if (this.hasRole([Profil.CLIENT, Profil.PROSPECT, Profil.CLIENT_RACCORDE])) {
      return this.habilitations.hasDelegationOffreStockage;
    }
    return false;
  }

  hasDelegationTransport(): boolean {
    if (this.hasRole([Profil.CLIENT, Profil.PROSPECT, Profil.CLIENT_RACCORDE])) {
      return this.habilitations.hasDelegationOffreTransport;
    }
    return false;
  }

  hasConnectedClientProfile(): boolean {
    return this.hasRole([Profil.CLIENT_RACCORDE]);
  }

  getPics(): Point[] | undefined {
    if (this.hasRole([Profil.CLIENT_RACCORDE])) {
      const userPics: Point[] = this.habilitations.stations.map((station) => {
        return {
          codePoint: station.codePoint,
          picName: station.picName,
        };
      });
      return Array.from(new Set(userPics));
    }
    return undefined;
  }
}
