<div id="consent" *ngIf="showConsent">
  <img class="close-icon pointer" src="assets/common/img/close-icon.svg" (click)="closeConsent()">
  <div class="d-flex justify-content-center align-items-center details content">
    <img src="assets/common/img/mask.svg">
    <span class="text">
      {{'CONSENT.TEXT' | translate}}
    </span>
    <button type="submit" class="accept" (click)="acceptConset()"><span class="text">{{'CONSENT.AGREE' | translate}}</span></button>
  </div>
</div>
