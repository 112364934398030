import { Injectable } from '@angular/core';
import { AuthenticationDetails } from './models/authentication-details.model';
import { Utilisateur } from '../../user/utilisateur';
import { LocalStorageStarService } from '@terega-portal/shared-lib';

@Injectable()
export class AuthCachingService {
  private LS_AUTH_DETAILS = 'auth-details';

  constructor(private localStorage: LocalStorageStarService) {}

  getAuthDetails(): AuthenticationDetails {
    const storedAuthDetails = this.localStorage.get(this.LS_AUTH_DETAILS);
    if (storedAuthDetails) {
      storedAuthDetails.utilisateur = new Utilisateur().fromData(storedAuthDetails.utilisateur);
    }
    return storedAuthDetails;
  }

  setAuthDetails(authDetails: AuthenticationDetails): void {
    this.localStorage.set(this.LS_AUTH_DETAILS, authDetails);
  }
}
