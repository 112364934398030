import { Component, Input, OnInit } from '@angular/core';
import { CMSComponentData, CMSComponent } from '../../../models/cms.model';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'cms-misc-cta',
  templateUrl: './cms-misc-cta.component.html',
  styleUrls: ['./cms-misc-cta.component.scss'],
})
export class CMSMiscCTAComponent implements CMSComponent, OnInit {
  @Input('componentData') public readonly componentData: CMSCTA;
  public expandLink;

  ngOnInit(): void {
    if (this.componentData.fields.pageLink) {
      this.expandLink = environment.cms.basepath + this.componentData.fields.pageLink.fields.slug;
    }
  }
}

export interface CMSCTA extends CMSComponentData {
  fields: {
    label: string;
    pageLink: {
      fields: {
        slug: string;
      };
    };
  };
}
